import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/messaging'

import { LoginForm, Residents, News, CreateNewsForm, EditNewsForm, Tasks, RegistrationOfferForm, ConfirmationForm, PasswordChangeForm,
    ChatsNew, Meters, MetersForm, Settings, ResidentForm, Profile, NotFound, Cameras, CreateCameraForm, EditCameraForm, CreateTaskForm, AvatarForm,
    PasswordChangeLoggedForm,
} from './pages'
import {
    SettingsReadings,
    SettingsReadingsForm,
    SettingsStructure,
    SettingsProfile,
    AddEmployeeForm,
    BuildingPassport,
    SettingsObjectsForm,
    EditEmployeeForm,
} from './pages/settings/settings-pages'
import {
    CreateResidentForm,
    DownloadResidentForm,
    EditResidentForm
} from './pages/resident-form/resident-form-pages'
import StaffPage from './pages/settings/settings-pages/staff/staff-page'
import { makeIsLoading, makeIsRequestError } from './__data__/selectors/common'
import { makeIsTasksLoading } from './__data__/selectors/tasks'
import { makeIsBuildingsListRS, makeIsAllBuildings } from './__data__/selectors/buildings'
import { makeNavigationBuildingsList } from './__data__/selectors/navigation'
import Navigation from './components/navigation'
import Loader from './components/loader'
import Carousel from './components/image-carousel'
import Story from './components/story-carousel'
import Notifications from './components/notifications'
import { getBuildingsList, setGlobalBuilding } from './__data__/actions/buildings'
import { getMessagingDeviceToken, pushNotification } from './__data__/actions/notifications'
import { getToken } from './__data__/utils'
import {
    MODERATOR_STORAGE_NAME,
    URL_LIST,
    FORM_NAMES,
    PAGE_KEYS,
    UNAUTHORIZED_PAGES,
} from './__data__/constants'
import RedirectHandler from './components/redirect-handler'
import AccessDeniedHandler from './components/access-denied-handler'
import { useChatLogin } from './components/custom-hooks/use-chat-login'
import PasswordCreatingForm from "./pages/password-creating-form";
import { checkPermissionForSection } from "./__data__/actions/permissions";

function Routes({ buildingsList, isAllBuildings, isLoading, token, tinode }) {

    return (
        <>
            { isLoading && <Loader /> }
            <Carousel />
            <Story />
            <Notifications />
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={token ? URL_LIST.news : URL_LIST.loginForm} />
                    </Route>
                    <Route path={URL_LIST.loginForm} exact>
                        <RedirectHandler>
                            <LoginForm />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.confirmationForm} exact>
                        <RedirectHandler>
                            <ConfirmationForm />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.passwordCreatingForm} exact>
                        <RedirectHandler>
                            <PasswordCreatingForm />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.passwordChangeForm} exact>
                        <RedirectHandler>
                            <PasswordChangeForm />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.passwordChangeLoggedForm} exact>
                        <RedirectHandler>
                            <PasswordChangeLoggedForm />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.registrationOfferForm} exact>
                        <RedirectHandler>
                            <RegistrationOfferForm />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.news} exact>
                        <RedirectHandler>
                            <Navigation
                                buildingsList={buildingsList}
                                pageTitle='Новости'
                                pageKey={PAGE_KEYS.news}
                            >
                                <AccessDeniedHandler>
                                    <News />
                                </AccessDeniedHandler>
                            </Navigation>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.createNewsForm} exact>
                        <RedirectHandler>
                            <CreateNewsForm formName={FORM_NAMES.createNews} />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.editNewsForm} exact>
                        <RedirectHandler>
                            <EditNewsForm formName={FORM_NAMES.editNews} />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.tasks} exact>
                        <RedirectHandler>
                            <Navigation
                                buildingsList={buildingsList}
                                pageTitle='Заявки'
                                pageKey={PAGE_KEYS.tasks}
                            >
                                <AccessDeniedHandler>
                                    <Tasks />
                                </AccessDeniedHandler>
                            </Navigation>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.createTask} exact>
                        <RedirectHandler>
                            <CreateTaskForm formName={FORM_NAMES.createTask} />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.chats} exact>
                        <RedirectHandler>
                            <Navigation
                                buildingsList={buildingsList}
                                pageTitle='Общение'
                                pageKey={PAGE_KEYS.chats}
                            >
                                <AccessDeniedHandler>
                                    <ChatsNew
                                        tinode={tinode}
                                    />
                                </AccessDeniedHandler>
                            </Navigation>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.cameras} exact>
                        <RedirectHandler>
                            <Navigation
                                buildingsList={buildingsList}
                                pageTitle='Камеры'
                                pageKey={PAGE_KEYS.cameras}
                            >
                                <AccessDeniedHandler>
                                    <Cameras />
                                </AccessDeniedHandler>
                            </Navigation>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.createCameraForm} exact>
                        <RedirectHandler>
                            <CreateCameraForm formName={FORM_NAMES.createCamera}  />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.editCameraForm} exact>
                        <RedirectHandler>
                            <EditCameraForm formName={FORM_NAMES.editCamera}  />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.residents} exact>
                        <RedirectHandler>
                            <Navigation
                                buildingsList={buildingsList}
                                pageTitle='Резиденты'
                                pageKey={PAGE_KEYS.residents}
                            >
                                <AccessDeniedHandler>
                                    <Residents/>
                                </AccessDeniedHandler>
                            </Navigation>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.createResidentForm} exact>
                        <RedirectHandler>
                            <ResidentForm>
                                <CreateResidentForm formName={FORM_NAMES.createResident}/>
                            </ResidentForm>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.downloadResidentForm} exact>
                        <RedirectHandler>
                            <ResidentForm>
                                <DownloadResidentForm formName={FORM_NAMES.downloadResident}/>
                            </ResidentForm>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.editResidentForm} exact>
                        <RedirectHandler>
                            <EditResidentForm formName={FORM_NAMES.editResident}  />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.meters} exact>
                        <RedirectHandler>
                            <Navigation
                                buildingsList={buildingsList}
                                pageTitle='Показания'
                                pageKey={PAGE_KEYS.meters}
                            >
                                <AccessDeniedHandler>
                                    <Meters isAllBuildings={isAllBuildings} />
                                </AccessDeniedHandler>
                            </Navigation>
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.metersForm} exact>
                        <RedirectHandler>
                            <MetersForm formName={FORM_NAMES.createMeters}  />
                        </RedirectHandler>
                    </Route>

                    <Route exact path={URL_LIST.settings} render={() => (<Redirect to={checkPermissionForSection('Сотрудники') ? URL_LIST.settingsEmployeesPermissions : checkPermissionForSection('Объекты') ? URL_LIST.settingsBuildings : URL_LIST.settingsCompanyProfile} />)} />
                    <Route path={URL_LIST.settingsEmployeesPermissions} exact>
                        <Navigation
                            buildingsList={buildingsList}
                            pageTitle='Настройки'
                            pageKey={PAGE_KEYS.settings}
                        >
                            <Settings>
                                <StaffPage />
                            </Settings>
                        </Navigation>
                    </Route>
                    <Route path={URL_LIST.settingsAddEmployee} exact>
                        <RedirectHandler>
                            <AddEmployeeForm formName={FORM_NAMES.settingsAddEmployee} />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.settingsEditEmployee} exact>
                        <RedirectHandler>
                            <EditEmployeeForm formName={FORM_NAMES.settingsEditEmployee} />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.settingsMetersReadings} exact>
                        <Navigation
                            buildingsList={buildingsList}
                            pageTitle='Настройки'
                            pageKey={PAGE_KEYS.settings}
                        >
                            <Settings>
                                <SettingsReadings />
                            </Settings>
                        </Navigation>
                    </Route>
                    <Route path={URL_LIST.settingsMetersReadingsForm} exact>
                        <SettingsReadingsForm formName={FORM_NAMES.settingsMetersReadings}  />
                    </Route>
                    <Route path={URL_LIST.settingsObjectsForm} exact>
                        <RedirectHandler>
                            <SettingsObjectsForm formName={FORM_NAMES.settingsObjectsForm}  />
                        </RedirectHandler>
                    </Route>
                    <Route path={URL_LIST.settingsBuildings} exact>
                        <Navigation
                            buildingsList={buildingsList}
                            pageTitle='Настройки'
                            pageKey={PAGE_KEYS.settings}
                        >
                            <Settings>
                                <SettingsStructure availableBuildingsList={buildingsList}/>
                            </Settings>
                        </Navigation>
                    </Route>
                    <Route path={URL_LIST.settingsCompanyProfile} exact>
                        <Navigation
                            buildingsList={buildingsList}
                            pageTitle='Настройки'
                            pageKey={PAGE_KEYS.settings}
                        >
                            <Settings>
                                <SettingsProfile />
                            </Settings>
                        </Navigation>
                    </Route>
                    <Route path={URL_LIST.settingsBuildingPassport} exact>
                        <BuildingPassport formName={FORM_NAMES.settingsServices} />
                    </Route>

                    <Route path={URL_LIST.profile} exact>
                        <RedirectHandler>
                            <Navigation
                                buildingsList={buildingsList}
                                pageTitle='Профиль'
                                pageKey={PAGE_KEYS.profile}
                            >
                                <Profile />
                            </Navigation>
                        </RedirectHandler>
                    </Route>

                    <Route path={URL_LIST.avatarForm} exact>
                        <RedirectHandler>
                            <AvatarForm formName={FORM_NAMES.avatarForm} />
                        </RedirectHandler>
                    </Route>

                    <Route>
                        <Navigation
                            pageTitle='тупик'
                            pageKey={PAGE_KEYS.notFound}
                        >
                            <NotFound />
                        </Navigation>
                    </Route>
                </Switch>
            </Router>
        </>
    )
}

firebase.initializeApp({
    apiKey: "AIzaSyDJIppIdZWSQKF-Y5Ov5ukeA9nN62KF3ic",
    authDomain: "domcom-1.firebaseapp.com",
    projectId: "domcom-1",
    storageBucket: "domcom-1.appspot.com",
    messagingSenderId: "411952849138",
    appId: "1:411952849138:web:82f5e17dbe11e445c9306b",
    messagingVapidKey: 'BKjiTG2buD31kG9UAgxbM7nt1rrr18wv6qy9ien2yLf1-VtFKIr1Lf4b9KP6PC5ZHwZcV7RWv3zhUE88SLI6HhA'
})
// firebase.analytics()

const mess = firebase?.messaging?.isSupported() ? firebase.messaging() : null

function Wrapper({
     isLoading,
     isForcedLoading,
     isRequestError,
     buildingsList,
     getBuildingsList,
     setGlobalBuilding,
     isBuildingsListRS,
     isAllBuildings,
     getMessagingDeviceToken,
     pushNotification,
}) {
    const { tinode } = useChatLogin()
    const token = getToken(MODERATOR_STORAGE_NAME)
    const href = window.location.href
    const origin = window.location.origin
    const currentPageUrl = _.replace(href, `${origin}/`, '')
    const isLoginFormRedirect = !token && (!UNAUTHORIZED_PAGES.includes(window.location.pathname) && currentPageUrl)

    // Добавляем в урл страницы логина параметр страницы, с которой редиректнуло на страницу логина.
    if (isLoginFormRedirect) {
        window.location.href = `${URL_LIST.loginForm}?redirectPage=${currentPageUrl}`
    }

    useEffect(() => {
        if (mess && token) {
            getMessagingDeviceToken(firebase)
        }
    }, [token, mess])

    useEffect(() => {
        if (token) {
            getBuildingsList(token)
        }
    }, [token])

    if (mess) {
        mess.onMessage(function(payload) {
            // Update the UI to include the received message.
            pushNotification(payload)
        })
    }

    // Было     if (token && !buildingsList && isBuildingsListRS) return <Loader />
    // Но из-за isBuildingsListRS происходило 2 рендера

    if (token && !buildingsList) return <Loader />

    return <Routes
        tinode={tinode}
        buildingsList={buildingsList}
        setGlobalBuilding={setGlobalBuilding}
        isLoading={isLoading || isForcedLoading}
        token={token}
        isAllBuildings={isAllBuildings}
    />
}

const mapStateToProps = createStructuredSelector({
    isLoading: makeIsLoading(),
    isForcedLoading: makeIsTasksLoading(), // Здесь можно добавлять условия для принудительного лоадера. Говно, но пока так
    isTasksLoading: makeIsTasksLoading(),
    isRequestError: makeIsRequestError(),
    buildingsList: makeNavigationBuildingsList(),
    isBuildingsListRS: makeIsBuildingsListRS(),
    isAllBuildings: makeIsAllBuildings(),
})

const mapDispatchToProps = {
    getBuildingsList,
    setGlobalBuilding,
    getMessagingDeviceToken,
    pushNotification,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Wrapper)
