import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import classNames from 'classnames'
import _ from 'lodash'

import { setCarousel } from '../../../../../../../__data__/actions/news'
import loader from '../../../../../../../theme/blinking_loader.svg'

import style from './style.css'

function Attachment(props) {

    const handleImageClick = (e) => {
        e.preventDefault()
        // TODO: Необходимо сортировать картинки
        const names = _.map(props.imageNames, i => i.large.file)
        props.setCarousel({ ...props.carouselData, imageNames: names, triggerImage: props.imageName.large.file } )
    }

    if(!props.imageName) return <img className={style[props.size]} src={loader} alt="loader" />

    return (
        <div className={style.moreImagesAttrWrapper} onClick={handleImageClick} >
            <div className={classNames(style.container, style[props.size], props.moreImageCount && style.moreImagesAttrFilter)}>
                <img src={props.imageName.large.file} className={style.image} alt="file"/>
            </div>
            {props.moreImageCount && (
                <div className={style.moreImagesAttr}>
                    {`+${props.moreImageCount}`}
                </div>
            )}
        </div>
    )
}

const mapDispatchToProps = {
    setCarousel,
}

const withConnect = connect(
    null,
    mapDispatchToProps,
)

export default compose(withConnect)(Attachment)
