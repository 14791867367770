import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { reduxForm, Field } from 'redux-form'
import queryString from 'query-string'
import _ from 'lodash'

import {
    FORM_NAMES, URL_LIST,
    CAMERA_FORM_FIELDS_ERRORS,
} from '../../__data__/constants'
import { makeBuildingList } from '../../__data__/selectors/buildings'
import { makeCameraFormInit } from '../../__data__/selectors/camera'
import { FormConstructor, MultiSelectInput, TextInput } from '../../components/form'
import formStyle from '../../theme/form.css'
import {
    formTitleValidateMin,
    formTitleValidateMax,
    linkValidate,
} from '../../components/form/validation'

import { getCamera } from  '../../__data__/actions/cameras'

import submit from './submit'

function Component(props) {
    const { initialize, getCamera, initData, formName } = props

    const queryParams = queryString.parse(window.location.search)
    const id = queryParams?.id

    useEffect(() => {
        if (id) {
            getCamera(id)
        }
    }, [id])

    useEffect(() => {
        initialize({
            ...initData,
        })
    }, [initData.name])

    return (
        <FormConstructor
            pageTitle='Укажите новые данные камеры'
            goBackLinkText='Отменить и вернуться'
            goBackLinkUrl={URL_LIST.cameras}
            columnCount='5'
            buttonText='Сохранить изменения'
            formName={formName}
            onSubmit={submit}
            fieldErrors={CAMERA_FORM_FIELDS_ERRORS}
        >
            <Fragment>
                <div className={formStyle.groupSection}>
                    <Field
                        name="name"
                        component={TextInput}
                        type="text"
                        label="Название камеры"
                        value=''
                        placeholder=""
                        validate={[formTitleValidateMin, formTitleValidateMax]}
                    />
                    <Field
                        name="link"
                        component={TextInput}
                        type="text"
                        label="Ссылка на поток"
                        value=''
                        placeholder=""
                        validate={[linkValidate]}
                    />
                </div>
                <div className={formStyle.groupSection}>
                    <Field
                        name="accessibleBuildings"
                        component={MultiSelectInput}
                        label="Привязана к объектам"
                        options={props.buildingList}
                    />
                    { !_.isEmpty(initData?.disabledBuildings) && (
                        <Field
                            name="disabledBuildings"
                            component={MultiSelectInput}
                            label="Недоступные для редактирования"
                            options={props.buildingList}
                            disabled
                        />
                    )}

                </div>
            </Fragment>
        </FormConstructor>
    )
}

const mapStateToProps = () => createStructuredSelector({
    buildingList: makeBuildingList(),
    initData: makeCameraFormInit(),
})

const mapDispatchToProps = {
    getCamera,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)
// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.editCamera,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
