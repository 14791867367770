import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, getFormValues, reduxForm, change } from 'redux-form'
import classNames from 'classnames'

import { CHECKED_BUILDINGS_STORAGE_NAME, FORM_NAMES } from '../../../../__data__/constants'
import { fetchSetHomeSelect, fetchSetGlobalBuilding } from '../../../../__data__/actions/buildings'
import { makeIsHomeSelectActive, makeBuildingList } from '../../../../__data__/selectors/buildings'
import { makeCheckedBuildings } from '../../../../__data__/selectors/common'

import Action from './action'
import CancelAction from './cancel-action'
import BuildingCheckbox from './building-field'
import submit from './submit'
import style from './style.css'
import { hasChecked, getChecked, getBuildingsId, getTargetText } from './utils'

function Component(props) {
    const {
        initialize,
        buildingsList,
        formValues,
        changeField,
        setHomeSelect,
        isHomeSelectActive,
        setGlobalBuilding,
        checkedBuildings,
        buildingList,
        disabled
    } = props

    const setOptions = e => {
        e.preventDefault()
        setHomeSelect(!isHomeSelectActive)
    }

    useEffect(() => {
        const storageCheckedBuildings = localStorage.getItem(CHECKED_BUILDINGS_STORAGE_NAME) || ''
        const storageCheckedBuildingsId = storageCheckedBuildings.split(',')
        const fields = _.reduce(buildingsList, (result, tenant, tenantId) => {
            result[`group-${tenantId}`] = {}
            _.forEach(tenant.items, building => {
                const isChecked = _.includes(storageCheckedBuildingsId, building.value)
                result[`group-${tenantId}`][`building-${building.value}`] = isChecked
            })
            return result
        }, [])
        initialize({
            ...fields
        })

        // Если ни одно здание не выбрано, считаем что выбраны все. Иначе записываем выбранные. В ридакс стор.
        const realFields = _.reduce(buildingsList, (result, tenant, tenantId) => {
            result[tenantId] = {}
            _.forEach(tenant.items, building => {
                const isChecked = _.includes(storageCheckedBuildingsId, building.value)
                result[tenantId][building.value] = isChecked
            })
            return result
        }, {})
        const checkedList = getChecked(realFields)
        const allIdList = getBuildingsId(realFields)
        const list = _.isEmpty(checkedList) ? allIdList : checkedList

        setGlobalBuilding(list)
    }, [])

    const handleSetAll = useCallback(e => {
        e.preventDefault()

        // Очистить все
        _.forEach(buildingsList, (tenant, tenantId) => {
            _.forEach(tenant.items, building => {
                changeField(FORM_NAMES.buildingsForm, `group-${tenantId}.building-${building.value}`, false)
            })
        })
    }, [formValues])

    const handleSetTenant = useCallback(e => {
        e.preventDefault()
        const tenantId = e.currentTarget.dataset.id
        const tenantSlice = formValues[tenantId]

        const hasUnchecked = _.reduce(tenantSlice, (result, building) => {
            if (!building) {
                result = true
                return result
            }
            return result
        }, false)

        _.forEach(tenantSlice, (value, buildingId)  => {
            changeField(FORM_NAMES.buildingsForm, `${tenantId}.${buildingId}`, hasUnchecked)
        })

    }, [formValues])

    const hasCheckedValues = hasChecked(formValues)

    const targetText = getTargetText(checkedBuildings, buildingList)

    if (disabled) {
        return (
            <div className={style.container}>
                <div className={classNames(style.value, style.disabled)} onClick={setOptions}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.64762 1.33337C7.10585 1.33337 6.66667 1.77256 6.66667 2.31432V2.48276C6.66667 2.70593 6.77834 2.9143 6.96417 3.03788L7.11735 3.13974C7.49491 3.39082 8 3.12014 8 2.66671H14.6667V12.6667H12C11.6318 12.6667 11.3333 12.9652 11.3333 13.3334C11.3333 13.7016 11.6318 14 12 14H15C15.5523 14 16 13.5523 16 13V2.33337C16 1.78109 15.5523 1.33337 15 1.33337H7.64762ZM5.5547 3.70317C5.2188 3.47924 4.7812 3.47924 4.4453 3.70317L0.4453 6.36984C0.167102 6.55531 0 6.86754 0 7.20189V13C0 13.5523 0.447715 14 1 14H9C9.55228 14 10 13.5523 10 13V7.20189C10 6.86754 9.8329 6.55531 9.5547 6.36984L5.5547 3.70317ZM9.73695 4.00004C9.51404 4.00004 9.33333 4.18075 9.33333 4.40366C9.33333 4.53881 9.40098 4.665 9.51355 4.73981L10.3564 5.29999C10.3892 5.32176 10.4276 5.33337 10.467 5.33337C10.5773 5.33337 10.6667 5.24397 10.6667 5.13368V4.66671C10.6667 4.29852 10.3682 4.00004 10 4.00004H9.73695ZM12.6667 4.00004C12.2985 4.00004 12 4.29852 12 4.66671C12 5.0349 12.2985 5.33337 12.6667 5.33337C13.0349 5.33337 13.3333 5.0349 13.3333 4.66671C13.3333 4.29852 13.0349 4.00004 12.6667 4.00004ZM4.46312 5.34169C4.79069 5.13324 5.20931 5.13324 5.53688 5.34169L8.20354 7.03866C8.49199 7.22222 8.66667 7.54042 8.66667 7.88232V12.6667H6.66667V9.66671C6.66667 9.11442 6.21895 8.66671 5.66667 8.66671H4.33333C3.78105 8.66671 3.33333 9.11442 3.33333 9.66671V12.6667H1.33333V7.88232C1.33333 7.54042 1.50801 7.22222 1.79646 7.03866L4.46312 5.34169ZM12.6667 6.66671C12.2985 6.66671 12 6.96518 12 7.33337C12 7.70156 12.2985 8.00004 12.6667 8.00004C13.0349 8.00004 13.3333 7.70156 13.3333 7.33337C13.3333 6.96518 13.0349 6.66671 12.6667 6.66671ZM12.6667 9.33337C12.2985 9.33337 12 9.63185 12 10C12 10.3682 12.2985 10.6667 12.6667 10.6667C13.0349 10.6667 13.3333 10.3682 13.3333 10C13.3333 9.63185 13.0349 9.33337 12.6667 9.33337Z"
                            fill="#2D313A"/>
                    </svg>
                    <span className={style.valueText}>{targetText}</span>
                    <svg className={classNames(style.valueIcon, style.disabled)}
                         width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.32562 0.281509C4.69807 -0.0938358 5.30193 -0.0938358 5.67438 0.281509L9.72066 4.35925C10.0931 4.73459 10.0931 5.34315 9.72066 5.71849C9.34821 6.09384 8.74435 6.09384 8.3719 5.71849L5 2.32038L1.6281 5.71849C1.25565 6.09384 0.651788 6.09384 0.279337 5.71849C-0.0931125 5.34315 -0.0931125 4.73459 0.279338 4.35925L4.32562 0.281509Z"
                            fill="#1E1F21"/>
                    </svg>
                </div>
            </div>
        )
    }

    return (
        <div className={style.container}>
            <div className={classNames(style.value, isHomeSelectActive && style.valueActive)} onClick={setOptions}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.64762 1.33337C7.10585 1.33337 6.66667 1.77256 6.66667 2.31432V2.48276C6.66667 2.70593 6.77834 2.9143 6.96417 3.03788L7.11735 3.13974C7.49491 3.39082 8 3.12014 8 2.66671H14.6667V12.6667H12C11.6318 12.6667 11.3333 12.9652 11.3333 13.3334C11.3333 13.7016 11.6318 14 12 14H15C15.5523 14 16 13.5523 16 13V2.33337C16 1.78109 15.5523 1.33337 15 1.33337H7.64762ZM5.5547 3.70317C5.2188 3.47924 4.7812 3.47924 4.4453 3.70317L0.4453 6.36984C0.167102 6.55531 0 6.86754 0 7.20189V13C0 13.5523 0.447715 14 1 14H9C9.55228 14 10 13.5523 10 13V7.20189C10 6.86754 9.8329 6.55531 9.5547 6.36984L5.5547 3.70317ZM9.73695 4.00004C9.51404 4.00004 9.33333 4.18075 9.33333 4.40366C9.33333 4.53881 9.40098 4.665 9.51355 4.73981L10.3564 5.29999C10.3892 5.32176 10.4276 5.33337 10.467 5.33337C10.5773 5.33337 10.6667 5.24397 10.6667 5.13368V4.66671C10.6667 4.29852 10.3682 4.00004 10 4.00004H9.73695ZM12.6667 4.00004C12.2985 4.00004 12 4.29852 12 4.66671C12 5.0349 12.2985 5.33337 12.6667 5.33337C13.0349 5.33337 13.3333 5.0349 13.3333 4.66671C13.3333 4.29852 13.0349 4.00004 12.6667 4.00004ZM4.46312 5.34169C4.79069 5.13324 5.20931 5.13324 5.53688 5.34169L8.20354 7.03866C8.49199 7.22222 8.66667 7.54042 8.66667 7.88232V12.6667H6.66667V9.66671C6.66667 9.11442 6.21895 8.66671 5.66667 8.66671H4.33333C3.78105 8.66671 3.33333 9.11442 3.33333 9.66671V12.6667H1.33333V7.88232C1.33333 7.54042 1.50801 7.22222 1.79646 7.03866L4.46312 5.34169ZM12.6667 6.66671C12.2985 6.66671 12 6.96518 12 7.33337C12 7.70156 12.2985 8.00004 12.6667 8.00004C13.0349 8.00004 13.3333 7.70156 13.3333 7.33337C13.3333 6.96518 13.0349 6.66671 12.6667 6.66671ZM12.6667 9.33337C12.2985 9.33337 12 9.63185 12 10C12 10.3682 12.2985 10.6667 12.6667 10.6667C13.0349 10.6667 13.3333 10.3682 13.3333 10C13.3333 9.63185 13.0349 9.33337 12.6667 9.33337Z" fill="#2D313A"/>
                </svg>
                <span className={style.valueText}>{targetText}</span>
                <svg className={classNames(style.valueIcon, !isHomeSelectActive && style.valueActiveIcon)} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.32562 0.281509C4.69807 -0.0938358 5.30193 -0.0938358 5.67438 0.281509L9.72066 4.35925C10.0931 4.73459 10.0931 5.34315 9.72066 5.71849C9.34821 6.09384 8.74435 6.09384 8.3719 5.71849L5 2.32038L1.6281 5.71849C1.25565 6.09384 0.651788 6.09384 0.279337 5.71849C-0.0931125 5.34315 -0.0931125 4.73459 0.279338 4.35925L4.32562 0.281509Z" fill="#1E1F21"/>
                </svg>
            </div>
            {
                isHomeSelectActive && (
                    <div className={style.options}>
                        <form className={style.form}>
                            <div className={style.selectAllButtonWrapper}>
                                <div className={classNames(style.selectAllButtonContainer, !hasCheckedValues && style.selectAllButtonContainerChecked )}>
                                    <a onClick={handleSetAll} className={style.selectAllButton} href="/">Все объекты</a>
                                    {!hasCheckedValues && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.3273 4.68382C12.9482 4.3078 12.3348 4.31476 11.9644 4.69929L6.5577 10.3121L3.99111 7.43123C3.63681 7.03355 3.02191 7.01304 2.6419 7.38623C2.28376 7.73794 2.26841 8.31008 2.60717 8.68049L5.84229 12.2178C6.23048 12.6422 6.89582 12.6525 7.29693 12.2403L13.3392 6.03031C13.7052 5.65417 13.6999 5.05343 13.3273 4.68382Z" fill="black" />
                                        </svg>
                                    )}
                                </div>
                            </div>
                            <div>
                                {_.map(buildingsList, (tenant, tenantId) => (
                                    <div key={tenantId} className={style.groupContainer}>
                                        <a className={style.groupName} onClick={handleSetTenant} href="/" data-id={`group-${tenantId}`}>{tenant.name}</a>
                                        <div className={style.fields}>
                                            {_.map(tenant.items, (building, key) => {
                                                const buildingId = 'building-' + building.value
                                                const tId = 'group-' + tenantId
                                                return (
                                                    // TODO: insert a typed emoji:
                                                    // <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    //      xmlns="http://www.w3.org/2000/svg">
                                                    //     <path
                                                    //         d="M7.64762 1.33337C7.10585 1.33337 6.66667 1.77256 6.66667 2.31432V2.48276C6.66667 2.70593 6.77834 2.9143 6.96417 3.03788L7.11735 3.13974C7.49491 3.39082 8 3.12014 8 2.66671H14.6667V12.6667H12C11.6318 12.6667 11.3333 12.9652 11.3333 13.3334C11.3333 13.7016 11.6318 14 12 14H15C15.5523 14 16 13.5523 16 13V2.33337C16 1.78109 15.5523 1.33337 15 1.33337H7.64762ZM5.5547 3.70317C5.2188 3.47924 4.7812 3.47924 4.4453 3.70317L0.4453 6.36984C0.167102 6.55531 0 6.86754 0 7.20189V13C0 13.5523 0.447715 14 1 14H9C9.55228 14 10 13.5523 10 13V7.20189C10 6.86754 9.8329 6.55531 9.5547 6.36984L5.5547 3.70317ZM9.73695 4.00004C9.51404 4.00004 9.33333 4.18075 9.33333 4.40366C9.33333 4.53881 9.40098 4.665 9.51355 4.73981L10.3564 5.29999C10.3892 5.32176 10.4276 5.33337 10.467 5.33337C10.5773 5.33337 10.6667 5.24397 10.6667 5.13368V4.66671C10.6667 4.29852 10.3682 4.00004 10 4.00004H9.73695ZM12.6667 4.00004C12.2985 4.00004 12 4.29852 12 4.66671C12 5.0349 12.2985 5.33337 12.6667 5.33337C13.0349 5.33337 13.3333 5.0349 13.3333 4.66671C13.3333 4.29852 13.0349 4.00004 12.6667 4.00004ZM4.46312 5.34169C4.79069 5.13324 5.20931 5.13324 5.53688 5.34169L8.20354 7.03866C8.49199 7.22222 8.66667 7.54042 8.66667 7.88232V12.6667H6.66667V9.66671C6.66667 9.11442 6.21895 8.66671 5.66667 8.66671H4.33333C3.78105 8.66671 3.33333 9.11442 3.33333 9.66671V12.6667H1.33333V7.88232C1.33333 7.54042 1.50801 7.22222 1.79646 7.03866L4.46312 5.34169ZM12.6667 6.66671C12.2985 6.66671 12 6.96518 12 7.33337C12 7.70156 12.2985 8.00004 12.6667 8.00004C13.0349 8.00004 13.3333 7.70156 13.3333 7.33337C13.3333 6.96518 13.0349 6.66671 12.6667 6.66671ZM12.6667 9.33337C12.2985 9.33337 12 9.63185 12 10C12 10.3682 12.2985 10.6667 12.6667 10.6667C13.0349 10.6667 13.3333 10.3682 13.3333 10C13.3333 9.63185 13.0349 9.33337 12.6667 9.33337Z"
                                                    //         fill="#2D313A"/>
                                                    // </svg>
                                                <Field
                                                    name={`${tId}.${buildingId}`}
                                                    component={BuildingCheckbox}
                                                    type="checkbox"
                                                    label={building.label}
                                                    id={`${tenantId}.${building.value}`}
                                                    validate={[]}
                                                    key={`${key}${building.label}`}
                                                />
                                            )
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </form>
                        <Action formName={FORM_NAMES.buildingsForm} setHomeSelect={setHomeSelect}/>
                        <CancelAction
                            formName={FORM_NAMES.buildingsForm}
                            initialize={initialize}
                            buildingsList={buildingsList}
                            setHomeSelect={setHomeSelect}
                        />
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = () => createStructuredSelector({
    formValues: getFormValues(FORM_NAMES.buildingsForm),
    isHomeSelectActive: makeIsHomeSelectActive(),
    checkedBuildings: makeCheckedBuildings(),
    buildingList: makeBuildingList(),
})

const mapDispatchToProps = {
    changeField: change,
    setHomeSelect: fetchSetHomeSelect,
    setGlobalBuilding: fetchSetGlobalBuilding,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.buildingsForm,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
