import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {createStructuredSelector} from 'reselect'
import {reduxForm, getFormValues} from 'redux-form'
import queryString from 'query-string'
import _ from 'lodash'
import {Element} from 'react-scroll'
import style from '../style.css'

import {FormConstructor} from '../../../../../components/form'
import {
    EDIT_EMPLOYEE_FORM_FIELDS_ERRORS,
    EMPLOYEES_SECTIONS,
    FORM_NAMES,
    URL_LIST
} from '../../../../../__data__/constants'
import PersonalInfoFields from '../componets/personal-info-fields'
import SectionFields from '../componets/section-fields'
import ObjectFields from '../componets/object-fields'
import ServicesFields from '../componets/services-fields'
import {getEmployee, getServices} from '../../../actions/staff-actions'
import {ScreenTabs} from '../../../../../components/form'
import {makeEmployeeFormOrganisations} from '../../../../../__data__/selectors'
import {isUserAdmin} from '../../../../../__data__/utils'
import {getEmployeeFormOrganisations} from '../../../actions/actions'
import {makeEmployeeInitialData, makeServiceList} from '../../../selectors/staff-selectors'

import submit from './submit'

const tabs = [
    {
        title: '1. Личные данные',
        value: 'personalInfo',
        enabled: true
    },
    {
        title: '2. Разделы',
        value: 'sectionsAccess',
        enabled: true
    },
    {
        title: '3. Объекты',
        value: 'objectAccess',
        enabled: true
    },
    isUserAdmin && {
        title: '4. Сервисы',
        value: 'services',
        enabled: false,
        textBlock: 'сервисы необходимы только для исполнителей, выберите "2. Разделы -> Заявки -> Быть исполнителем"'
    },
]

function Component(props) {
    const {
        getEmployeeFormOrganisations,
        getEmployee,
        initialize,
        initialEditEmployeeData,
        formValues,
        organisationList,
        serviceList,
        getServices,
    } = props

    const queryParams = queryString.parse(window.location.search)
    const employeeId = _.get(queryParams, 'id')
    const [selectTab, setSelectTab] = React.useState('personalInfo')

    useEffect(() => {
        getEmployee(employeeId)
        getServices()
    }, [employeeId])

    useEffect(() => {
        if (initialEditEmployeeData && organisationList && serviceList) {
            const companyId = initialEditEmployeeData.companyId
            const company = _.find(organisationList, i => i.id === companyId)
            initialize({...initialEditEmployeeData, company})
        }
    }, [_.get(initialEditEmployeeData, 'user'), _.get(organisationList, [0, 'id']), !!serviceList])

    useEffect(() => {
        getEmployeeFormOrganisations()
    }, [])

    useEffect(() => {
        document.body.style.overflow = "unset"
        document.body.style.height = "auto"
    }, [])

    const handleTabClick = (selectTab) => {
        setSelectTab(selectTab)
    }

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('[scroll]');
            let activeElement = null;
            elements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                const vh = window.innerHeight || document.documentElement.clientHeight;

                // Проверяем, виден ли элемент хотя бы частично
                const isPartiallyVisible = (
                    rect.top < vh &&
                    rect.bottom > 0
                );

                if (isPartiallyVisible) {
                    // Вычисляем процент видимости элемента
                    const visiblePercent = Math.min(100, Math.max(0, (vh - rect.top) / rect.height * 100));

                    // Если процент видимости больше 60, обновляем активный элемент
                    if (visiblePercent >= 60) {
                        // maxVisiblePercent = visiblePercent;
                        activeElement = element;
                    }
                }
            });

            // Если последний элемент виден, то устанавливаем его в качестве активного
            const lastElement = elements[elements.length - 1];
            const lastRect = lastElement.getBoundingClientRect();
            if (lastRect.bottom <= window.innerHeight) {
                activeElement = lastElement;
            }

            if (activeElement) {
                setSelectTab(activeElement.getAttribute('name'));
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!formValues) return null
    console.log("FORM_VALUES")
    console.log(formValues)
    let initServicesTab = tabs.find(tab => tab.value === 'services' && tab.textBlock);
    let isPerformerEnabledInTask = formValues.tasks === EMPLOYEES_SECTIONS.performer
    if (initServicesTab && isPerformerEnabledInTask) {
        // зачищаем дефолтные сервисы с тултипом при выставлении EMPLOYEES_SECTIONS.performer в formValues.tasks
        tabs.pop()
        tabs.push({
            title: '4. Сервисы',
            value: 'services',
            enabled: true
        });
    } else if (!initServicesTab && !isPerformerEnabledInTask) {
        // возвращаем дефолтные сервисы с тултипом при удалении EMPLOYEES_SECTIONS.performer в formValues.tasks
        tabs.pop()
        tabs.push({
            title: '4. Сервисы',
            value: 'services',
            enabled: false,
            textBlock: 'сервисы необходимы только для исполнителей, выберите "2. Разделы -> Заявки -> Быть исполнителем"'
        });
    }

    return (
        <FormConstructor
            pageTitle='Уточните данные сотрудника'
            goBackLinkText='Вернуться к списку сотрудников'
            goBackLinkUrl={URL_LIST.settingsEmployeesPermissions}
            columnCount='5'
            buttonText='Сохранить настройки'
            formName={FORM_NAMES.settingsEditEmployee}
            onSubmit={submit}
            contentTop={true}
            fieldErrors={EDIT_EMPLOYEE_FORM_FIELDS_ERRORS}
        >
            <ScreenTabs useScroll={true} items={tabs} handleClick={handleTabClick} activeItem={selectTab}/>
            <Element name="personalInfo" scroll="true">
                <PersonalInfoFields organisationList={organisationList}/>
            </Element>
            <div className={style.spacing}></div>
            <Element name="sectionsAccess" scroll="true">
                <SectionFields formValues={formValues}/>
            </Element>
            <div className={style.spacing}></div>
            <Element name="objectAccess" scroll="true">
                <ObjectFields formValues={formValues} formName={FORM_NAMES.settingsEditEmployee}/>
            </Element>
            <div className={style.spacing}></div>
            {
                isPerformerEnabledInTask && (
                    <Element name="services" scroll="true">
                        <ServicesFields serviceList={serviceList}/>
                    </Element>
                )
            }
        </FormConstructor>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
    initialEditEmployeeData: makeEmployeeInitialData(),
    organisationList: makeEmployeeFormOrganisations(),
    serviceList: makeServiceList(),
})

const mapDispatchToProps = {
    getEmployee,
    getEmployeeFormOrganisations,
    getServices,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.settingsEditEmployee,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
