import React from 'react'
import { connect } from "react-redux";
import { compose } from "redux";
import { blockResident } from "../../actions/resident-actions";
import style from './item.css'

function Component(props) {
    const { userID, blockEmployee, status, callback } = props

    const handleClick = e => {
        blockEmployee(userID, status !== 'suspended')
        callback()
    }

    return (
        <button
            className={style.container}
            type='button'
            onClick={handleClick}
        >
            {status !== 'suspended' ? 'Заблокировать' : 'Разблокировать' }
        </button>

    )
}

const mapDispatchToProps = {
    blockEmployee: blockResident,
}

const withConnect = connect(
    null,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)

