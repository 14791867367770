import React, { Fragment } from 'react'
import { compose } from 'redux'

import Loader from '../../../../../components/local-loader'
import { getFileFormatIcon, getIsFileImage } from '../../../../../__data__/utils'
import style from '../../style.css'
import { fileFormatIcons } from '../../../../../__data__/constants'

function Component(props) {
    const { name, status, link } = props
    const isImage = getIsFileImage(name)
    const fileIcon = getFileFormatIcon(name)
    const errorIcon = fileFormatIcons['error']

    if (!link || status === 'uploading') return <Loader />
    if (status === 'error') return (
        <div className={style.fileIconWrapper}>
            <img alt="error" className={style.fileIcon} src={errorIcon} />
        </div>
    )

    if (status === 'done') return (
        <Fragment>
            { isImage ?
                (
                    <img
                        alt="priviewImage"
                        className='dzu-previewImage'
                        src={link}
                        onError={(event)=>event.target.setAttribute('src', props.link)}
                    />
                ) : (
                    <div className={style.fileIconWrapper}>
                        <img alt="file" className={style.fileIcon} src={fileIcon} />
                    </div>
                )
            }
        </Fragment>
    )
    return null
}

export default compose()(Component)
