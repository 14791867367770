import * as types from './actions-types'
import _ from 'lodash'

import { URL_LIST } from './constants'

import {
    GET_SETTINGS_EMPLOYEE,
    GET_SETTINGS_EMPLOYEE_FAIL,
    GET_SETTINGS_EMPLOYEE_SUCCESS,
    GET_SETTINGS_STAFF_PAGINATION,
    GET_SETTINGS_STAFF_SUCCESS_PAGINATION,
    GET_SETTINGS_STAFF_FAIL_PAGINATION,
    GET_COMPANY_INFORMATION,
    GET_COMPANY_INFORMATION_SUCCESS,
    GET_COMPANY_INFORMATION_FAIL,
    GET_SETTINGS_BUILDINGS_PAGINATION,
    GET_SETTINGS_BUILDINGS_SUCCESS_PAGINATION,
    GET_SETTINGS_BUILDINGS_FAIL_PAGINATION,
    GET_SETTINGS_OBJECT,
    GET_SETTINGS_OBJECT_SUCCESS,
    GET_SETTINGS_OBJECT_FAIL,
} from '../pages/settings/actions/actions-types'

export const initialState = {
    isLoading: false,
    isRequestError: false,
    isAccessDenied: false,
    isNewsListChanged: false,
    redirectUrl: null,
    isHomeSelectActive: false,
    isEmployeeListChanged: false,
    notifications: [],
    notificationGrowIndex: -2,
    tasks: {},
    isTasksLoading: {},
    isTasksUpdating: {},
    tasksCount: {},
    isTaskPatchError: false,
    residentsFilters: {},
}

function reducer(state = initialState, action) {
    const isAccessDenied = action.error?.response?.status === 401
    switch (action.type) {
        /* Login rq */
        case types.LOGIN: {
            return {
                ...state,
                isLoading: true,
                isRequestError: false,
                isAccessDenied: false,
            }
        }
        case types.LOGIN_SUCCESS: {
            return {
                ...state,
                redirectUrl: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.LOGIN_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_PERMISSIONS: {
            return {
                ...state,
                isLoading: true,
                isRequestError: false,
                isAccessDenied: false,
            }
        }
        case types.GET_PERMISSIONS_SUCCESS: {
            return {
                ...state,
                redirectUrl: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_PERMISSIONS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SEND_ACCOUNT_CONFIRMATION_CODE: {
            return {
                ...state,
                sendSmsPassGeneration: {},
                isLoading: true,
                isRequestError: false,
                isAccessDenied: false,
            }
        }
        case types.SEND_ACCOUNT_CONFIRMATION_CODE_SUCCESS: {
            return {
                ...state,
                sendSmsPassGeneration: {
                    userName: action?.data?.data?.username,
                    requestCooldown: action?.data?.data?.cooldown_seconds,
                    isRSFinished: true,
                    isError: false,
                },
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.SEND_ACCOUNT_CONFIRMATION_CODE_FAIL: {
            const errorCode = action?.error?.response?.status
            const isForbidden = errorCode === 403

            if (isForbidden) {
                return {
                    ...state,
                    sendSmsPassGeneration: {
                        requestCooldown: action?.error?.response?.data?.cooldown_seconds,
                        userName: action?.error?.response?.data?.username,
                        isError: true,
                        errorCode: 403,
                        isRSFinished: true,
                    },
                    isLoading: false,
                    isRequestError: true,
                }
            }

            return {
                ...state,
                sendSmsPassGeneration: {
                    ...state.sendSmsPassGeneration,
                    isError: true,
                    errorCode: errorCode,
                    isRSFinished: true,
                },
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SET_ACCOUNT_CONFIRMATION_CODE: {
            return {
                ...state,
                fillSmsPassGeneration: {
                    ...state.fillSmsPassGeneration,
                    requestCooldown: 0,
                },
                isLoading: true,
                isRequestError: false,
                isAccessDenied: false,
            }
        }
        case types.SET_ACCOUNT_CONFIRMATION_CODE_SUCCESS: {
            return {
                ...state,
                fillSmsPassGeneration: {
                    isRSFinished: true,
                    isError: false,
                    token: action?.data?.data?.token,
                },
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.SET_ACCOUNT_CONFIRMATION_CODE_FAIL: {
            const errorCode = action?.error?.response?.status
            const isForbidden = errorCode === 403

            if (isForbidden) {
                return {
                    ...state,
                    fillSmsPassGeneration: {
                        requestCooldown: action?.error?.response?.data?.cooldown_seconds,
                        userName: action?.error?.response?.data?.username,
                        isError: true,
                        errorCode: 403,
                        isRSFinished: true,
                    },
                    isLoading: false,
                    isRequestError: true,
                    errorCode: 403
                }
            }

            return {
                ...state,
                fillSmsPassGeneration: {
                    ...state.fillSmsPassGeneration,
                    isError: true,
                    errorCode: errorCode,
                    isRSFinished: true,
                },
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SET_PASSWORD: {
            return {
                ...state,
                isLoading: true,
                isRequestError: false,
                isAccessDenied: false,
            }
        }
        case types.SET_PASSWORD_SUCCESS: {
            return {
                ...state,
                isPasswordChanged: true,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.SET_PASSWORD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.CLEAR_IS_PASSWORD_CHANGED: {
            return {
                ..._.omit(state, ['isPasswordChanged', 'sendSmsPassGeneration', 'fillSmsPassGeneration']),
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.CLEAR_REDIRECT_URL: {
            return {...state, redirectUrl: null}
        }

        case types.SET_GLOBAL_BUILDING: {
            return {...state, checkedBuildings: action.buildingsId}
        }

        case types.SET_HOME_SELECT: {
            return { ...state, isHomeSelectActive: action.isHomeSelectActive }
        }

        case types.GET_RESIDENTS_LIST: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_RESIDENTS_LIST_SUCCESS: {
            return {
                ...state,
                residents: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_RESIDENTS_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SET_RESIDENTS_FILTER: {
            return {
                ...state,
                residentsFilters: {...state.residentsFilters, ...action.data},
                isLoading: false,
                isRequestError: true,
            }
        }


        case types.GET_FILTERED_ENTRY: {
            return {
                ...state,
                isResidentsFilterLoading: true,
                isResidentsFilterError: false,
            }
        }
        case types.GET_FILTERED_ENTRY_SUCCESS: {
            return {
                ...state,
                filteredEntry: {...action.data},
                isResidentsFilterLoading: false,
                isResidentsFilterError: false,
            }
        }
        case types.GET_FILTERED_ENTRY_FAIL: {
            if (action?.type === "GET_FILTERED_ENTRY_FAIL") {
                return {
                    ...state,
                }
            }

            return {
                ...state,
                isResidentsFilterLoading: false,
                isResidentsFilterError: true,
            }
        }

        case types.GET_UNITS: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_UNITS_SUCCESS: {
            return {
                ...state,
                units: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_UNITS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_RESIDENT: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_RESIDENT_SUCCESS: {
            return {
                ...state,
                resident: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_RESIDENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }
        case types.CREATE_RESIDENT: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.CREATE_RESIDENT_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.residents,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.CREATE_RESIDENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }
        case types.EDIT_RESIDENT: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.EDIT_RESIDENT_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.residents,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.EDIT_RESIDENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_BUILDING_LIST: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
                isBuildingListRQ: true,
            }
        }
        case types.GET_BUILDING_LIST_SUCCESS: {
            return {
                ...state,
                buildings: [...action.data],
                isLoading: false,
                isRequestError: false,
                isBuildingListRQ: false,
            }
        }
        case types.GET_BUILDING_LIST_FAIL: {
            return {
                ...state,
                isAccessDenied,
                isLoading: false,
                isRequestError: true,
                isBuildingListRQ: false,
            }
        }
        case types.GET_BUILDING_DATA: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_BUILDING_DATA_SUCCESS: {
            return {
                ...state,
                building: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_BUILDING_DATA_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_PORCH_DATA: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_PORCH_DATA_SUCCESS: {
            return {
                ...state,
                porch: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_PORCH_DATA_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_METERS_LIST: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_METERS_LIST_SUCCESS: {
            return {
                ...state,
                meters: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_METERS_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }
        case types.GET_METERS_LAST_MONTH: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_METERS_LAST_MONTH_SUCCESS: {
            return {
                ...state,
                metersLastResult: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_METERS_LAST_MONTH_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_NEWS_LIST: {
            return {
                ...state,
                isLoading: true,
                isNewsListChanged: false,
                isAccessDenied: false,
            }
        }

        case types.GET_NEWS_LIST_SUCCESS: {
            return {
                ...state,
                newsList: action.data.results,
                newsListNextUrl: action.data.next,
                isLoading: false,
                isRequestError: false,
            }
        }

        case types.GET_NEWS_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_NEXT_NEWS_LIST: {
            return {
                ...state,
                isNextNewsListLoading: true,
            }
        }

        case types.GET_NEXT_NEWS_LIST_SUCCESS: {
            return {
                ...state,
                newsList: [...state.newsList, ...action.data.results],
                newsListNextUrl: action.data.next,
                isNextNewsListLoading: false,
                isRequestError: false,
            }
        }

        case types.GET_NEXT_NEWS_LIST_FAIL: {
            return {
                ...state,
                isNextNewsListLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_STORI_LIST: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }

        case types.GET_STORI_LIST_SUCCESS: {
            return {
                ...state,
                storyList: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }

        case types.GET_STORI_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_STORI_FULL: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_STORI_FULL_SUCCESS: {
            const newList = state.storyList
            const arrayNumberList = newList.findIndex((story) => story.id === action.data.id)
            newList[arrayNumberList].frames = action.data.frames
            return {
                ...state,
                storyListFull: newList,
                isLoading: false,
                isRequestError: false,
            }
        }

        case types.GET_STORI_FULL_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_NEWS: {
            return {
                ...state,
                isLoading: true,
                isNewsListChanged: false,
                isAccessDenied: false,
            }
        }
        case types.GET_NEWS_SUCCESS: {
            return {
                ...state,
                news: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_NEWS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.DELETE_NEWS: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }

        case types.DELETE_NEWS_SUCCESS: {
            return {
                ...state,
                isNewsListChanged: true,
                isLoading: false,
            }
        }

        case types.CREATE_NEWS: {
            return {
                ...state,
                isLoading: true,
                isNewsListChanged: false,
                isAccessDenied: false,
            }
        }
        case types.CREATE_NEWS_SUCCESS: {
            return {
                ..._.omit(state, 'newsList'),
                redirectUrl: URL_LIST.news,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.CREATE_NEWS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.EDIT_NEWS: {
            return {
                ...state,
                isLoading: true,
                isNewsListChanged: false,
                isAccessDenied: false,
            }
        }
        case types.EDIT_NEWS_SUCCESS: {
            /* Удаляю newsList и news из стора, т.к. они кешируются. Думаю, нужно использовать другой тип селлектора. */
            return {
                ..._.omit(state, ['newsList', 'news']),
                redirectUrl: URL_LIST.news,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.EDIT_NEWS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SET_CAROUSEL: {

            return {
                ...state,
                carousel: action.data
            }
        }

        case types.SET_STORI: {

            return {
                ...state,
                currentStory: action.data
            }
        }

        case types.GET_BUILDING_SETTINGS: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_BUILDING_SETTINGS_SUCCESS: {
            return {
                ...state,
                buildingSettings: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_BUILDING_SETTINGS_FAIL: {
            return {
                ...state,
                buildingSettings: {},
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_BUILDINGS_SETTINGS: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_BUILDINGS_SETTINGS_SUCCESS: {
            return {
                ...state,
                buildingsSettings: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_BUILDINGS_SETTINGS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case GET_SETTINGS_STAFF_PAGINATION: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case GET_SETTINGS_STAFF_SUCCESS_PAGINATION: {
            return {
                ...state,
                settingsStaff: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case GET_SETTINGS_STAFF_FAIL_PAGINATION: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case GET_SETTINGS_BUILDINGS_PAGINATION: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case GET_SETTINGS_BUILDINGS_SUCCESS_PAGINATION: {
            return {
                ...state,
                settingsBuildings: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case GET_SETTINGS_BUILDINGS_FAIL_PAGINATION: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case GET_SETTINGS_OBJECT: {
            return {
                ...state,
            }
        }
        case GET_SETTINGS_OBJECT_SUCCESS: {
            return {
                ...state,
                settingsObject: {...action.data},
            }
        }
        case GET_SETTINGS_OBJECT_FAIL: {
            return {
                ...state,
            }
        }

        case GET_COMPANY_INFORMATION: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case GET_COMPANY_INFORMATION_SUCCESS: {
            return {
                ...state,
                companyInformaton: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case GET_COMPANY_INFORMATION_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.CLEAR_EMPLOYEE: {
            return {
                ...state,
                isLoading: false,
                currentEmployee: null,
            }
        }

        case types.CREATE_EMPLOYEE: {
            return {
                ...state,
                isLoading: true,
                isEmployeeListChanged: false,
            }
        }

        case types.CREATE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isRequestError: false,
                redirectUrl: `${URL_LIST.settingsEditEmployee}?id=${action.data.user}&screen=service`,
            }
        }
        case types.CREATE_EMPLOYEE_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.UPDATE_EMPLOYEE: {
            return {
                ...state,
                isLoading: true,
                isEmployeeListChanged: false,
            }
        }

        case types.UPDATE_EMPLOYEE_SUCCESS: {
            return {
                ..._.omit(state, 'employee'),
                isLoading: false,
                isRequestError: false,
                redirectUrl: `${URL_LIST.settingsEmployeesPermissions}`,
            }
        }
        case types.UPDATE_EMPLOYEE_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.DELETE_EMPLOYEE: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
                isEmployeeListChanged: false
            }
        }

        case types.DELETE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isEmployeeListChanged: true
            }
        }

        case types.DELETE_EMPLOYEE_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
                isEmployeeListChanged: false
            }
        }

        case types.BLOCK_EMPLOYEE: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
                isEmployeeListChanged: false
            }
        }

        case types.BLOCK_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isEmployeeListChanged: true
            }
        }

        case types.BLOCK_EMPLOYEE_FAIL : {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
                isEmployeeListChanged: false
            }
        }

        case GET_SETTINGS_EMPLOYEE: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case GET_SETTINGS_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                employee: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case GET_SETTINGS_EMPLOYEE_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_EMPLOYEE_FORM_ORGANISATIONS: {
            return {
                ...state,
            }
        }
        case types.GET_EMPLOYEE_FORM_ORGANISATIONS_SUCCESS: {
            return {
                ...state,
                isRequestError: false,
                employeeFormOrganisations: action.data,
            }
        }
        case types.GET_EMPLOYEE_FORM_ORGANISATIONS_FAIL: {
            return {
                ...state,
                isRequestError: true,
            }
        }

        case types.GET_SETTINGS_READING: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_SETTINGS_READING_SUCCESS: {
            return {
                ...state,
                settingsReadings: [...action.data],
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_SETTINGS_READING_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_BUILDING_METERS_SETTINGS: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_BUILDING_METERS_SETTINGS_SUCCESS: {
            return {
                ...state,
                buildingMetersSettings: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_BUILDING_METERS_SETTINGS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SET_BUILDING_METERS_SETTINGS: {
            return {...state, isLoading: true}
        }
        case types.SET_BUILDING_METERS_SETTINGS_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.settingsBuildings,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.SET_BUILDING_METERS_SETTINGS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SEND_METERS: {
            return {...state, isLoading: true}
        }
        case types.SEND_METERS_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.meters,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.SEND_METERS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_CAMERA_LIST: {
            return {
                ...state,
                isLoading: true,
                isCameraListChanged: false,
                isAccessDenied: false,
            }
        }

        case types.GET_CAMERA_LIST_SUCCESS: {
            return {
                ...state,
                cameraList: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }

        case types.GET_CAMERA_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.CREATE_CAMERA: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.CREATE_CAMERA_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.cameras,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.CREATE_CAMERA_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.EDIT_CAMERA: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.EDIT_CAMERA_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.cameras,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.EDIT_CAMERA_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_CAMERA: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.GET_CAMERA_SUCCESS: {
            return {
                ...state,
                camera: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.GET_CAMERA_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.DELETE_CAMERA: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case types.DELETE_CAMERA_SUCCESS: {
            return {
                ...state,
                isCameraListChanged: true,
                isLoading: false,
            }
        }
        case types.DELETE_CAMERA_FAIL: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case types.GET_PROFILE: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }

        case types.GET_PROFILE_SUCCESS: {
            return {
                ...state,
                profile: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }

        case types.GET_PROFILE_FAIL: {
            if (action.error.response.status === 401) {
                return {
                    ...state,
                    profile: {
                        isTokenError: true
                    },
                    isLoading: false,
                    isRequestError: true,
                }
            }

            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_USER_PROFILE: {
            return {
                ...state
            }
        }

        case types.GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                userProfile: action.data,
                isLoading: false,
                isRequestError: false
            }
        }

        case types.GET_USER_PROFILE_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.SET_AVATAR: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }

        case types.SET_AVATAR_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isRequestError: false,
                redirectUrl: URL_LIST.profile,
            }
        }

        case types.SET_AVATAR_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.DELETE_AVATAR: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }

        case types.DELETE_AVATAR_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isRequestError: false,
            }
        }

        case types.DELETE_AVATAR_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.LOGGED_SMS: {
            return {
                ...state,
                sendSmsPassGeneration: {},
                isLoading: true,
                isRequestError: false,
                isAccessDenied: false,
            }
        }
        case types.LOGGED_SMS_SUCCESS: {
            return {
                ...state,
                sendSmsPassGeneration: {
                    userName: action?.data?.data?.username,
                    requestCooldown: action?.data?.data?.cooldown_seconds,
                    isRSFinished: true,
                    isError: false,
                },
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.LOGGED_SMS_FAIL: {
            const errorCode = action?.error?.response?.status
            const isForbidden = errorCode === 403

            if (isForbidden) {
                return {
                    ...state,
                    sendSmsPassGeneration: {
                        requestCooldown: action?.error?.response?.data?.cooldown_seconds,
                        userName: action?.error?.response?.data?.username,
                        isError: true,
                        errorCode: 403,
                        isRSFinished: true,
                    },
                    isLoading: false,
                    isRequestError: true,
                }
            }

            return {
                ...state,
                sendSmsPassGeneration: {
                    ...state.sendSmsPassGeneration,
                    isError: true,
                    errorCode: errorCode,
                    isRSFinished: true,
                },
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.LOGGED_CONFIRMATION_CODE: {
            return {
                ...state,
                fillSmsPassGeneration: {
                    ...state.fillSmsPassGeneration,
                    requestCooldown: 0,
                },
                isLoading: true,
                isRequestError: false,
                isAccessDenied: false,
            }
        }
        case types.LOGGED_CONFIRMATION_CODE_SUCCESS: {
            return {
                ...state,
                fillSmsPassGeneration: {
                    isRSFinished: true,
                    isError: false,
                    token: action?.data?.data?.token,
                },
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.LOGGED_CONFIRMATION_CODE_FAIL: {
            const errorCode = action?.error?.response?.status
            const isForbidden = errorCode === 403

            if (isForbidden) {
                return {
                    ...state,
                    fillSmsPassGeneration: {
                        requestCooldown: action?.error?.response?.data?.cooldown_seconds,
                        userName: action?.error?.response?.data?.username,
                        isError: true,
                        errorCode: 403,
                        isRSFinished: true,
                    },
                    isLoading: false,
                    isRequestError: true,
                    errorCode: 403
                }
            }

            return {
                ...state,
                fillSmsPassGeneration: {
                    ...state.fillSmsPassGeneration,
                    isError: true,
                    errorCode: errorCode,
                    isRSFinished: true,
                },
                isLoading: false,
                isRequestError: true,
            }
        }








        case types.FETCH_PUSH_NOTIFICATION: {
            const message = _.get(action, 'data.data.message')
            const type = _.get(action, 'data.data.type')
            const subtype = _.get(action, 'data.data.subtype')
            const ref = _.get(action, 'data.data.ref')
            const sentBy = _.get(action, 'data.data.sent_by')
            const id = `${_.get(action, 'data.data.id')}-${ref}`

            return {
                ...state,
                notifications: [ ...state.notifications, { message, type, subtype, ref, sentBy, id } ],
                notificationGrowIndex: -2,
            }
        }

        case types.FETCH_DELETE_NOTIFICATION: {
            const id = _.get(action, 'data.id')

            const notificationGrowIndex = _.findIndex(state.notifications, i => { return i.id === id }) -1
            const notifications = _.filter(state.notifications, i => { return i.id !== id })

            return {
                ...state,
                notifications,
                notificationGrowIndex,
            }
        }
        case types.FETCH_DELETE_ALL_NOTIFICATION: {

            return {
                ...state,
                notifications: [],
            }
        }

        case types.GET_SERVICES: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }

        case types.GET_SERVICES_SUCCESS: {
            return {
                ...state,
                services: action.data,
                isLoading: false,
                isRequestError: false,
            }
        }

        case types.GET_SERVICES_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_EXECUTORS: {
            return {
                ...state,

            }
        }

        case types.GET_EXECUTORS_SUCCESS: {
            return {
                ...state,
                serviceExecutors: action.data,
            }
        }

        case types.GET_EXECUTORS_FAIL: {
            return {
                ...state,
            }
        }

        case types.GET_TASKS: {
            const stage = action.data.stage
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
                isTasksLoading: { ...state.isTasksLoading, [stage]: true },
            }
        }
        case types.GET_TASKS_SUCCESS: {
            const stage = action.data.stage
            const list = action.data.data

            return {
                ...state,
                isLoading: false,
                isRequestError: false,
                tasks: { ...state.tasks, [stage]: list },
                isTasksLoading: { ...state.isTasksLoading, [stage]: false },
            }
        }
        case types.GET_TASKS_FAIL: {
            const stage = action.data.stage
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
                isTasksLoading: { ...state.isTasksLoading, [stage]: false }, // Нужно написать обработчик
            }
        }

        case types.SAVE_TASKS_TO_STORE_LIST: {
            return {
                ...state,
                tasks: action.data,
            }

        }

        case types.GET_NEXT_TASKS: {
            const stage = action.data.stage
            return {
                ...state,
                isTasksUpdating: { ...state.isTasksUpdating, [stage]: true },
            }
        }
        case types.GET_NEXT_TASKS_SUCCESS: {
            const stage = action.data.stage

            // При последующем получении тасок объединяем их с получеными ранее
            const existingList = state.tasks[stage] || []
            const list = [ ...action.data.data, ...existingList ]

            const sortedList = _.sortBy(list, i => {
                return i.order
            })

            return {
                ...state,
                tasks: { ...state.tasks, [stage]: sortedList },
                isTasksUpdating: { ...state.isTasksUpdating, [stage]: false },
            }
        }
        case types.GET_NEXT_TASKS_FAIL: {
            const stage = action.data.stage
            return {
                ...state,
                isTasksUpdating: { ...state.isTasksUpdating, [stage]: false }, // Нужно написать обработчик
            }
        }

        case types.CREATE_TASK: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.CREATE_TASK_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.tasks,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.CREATE_TASK_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.GET_TASK_DETAILS: {
            return {
                ...state,
                isTaskDetailsLoading: true,
            }
        }
        case types.GET_TASK_DETAILS_SUCCESS: {
            return {
                ...state,
                activeTask: action.data,
                isTaskDetailsLoading: false,
            }
        }
        case types.GET_TASK_DETAILS_FAIL: {
            return {
                ...state,
                isTaskDetailsLoading: false,
            }
        }


        case types.GET_PERFORMER_FORM_TASK_DETAILS: {
            return {
                ...state,
                isPerformerFormTaskDetailsLoading: true,
            }
        }
        case types.GET_PERFORMER_FORM_TASK_DETAILS_SUCCESS: {
            return {
                ...state,
                performerFormTask: action.data,
                isPerformerFormTaskDetailsLoading: false,
            }
        }
        case types.GET_PERFORMER_FORM_TASK_DETAILS_FAIL: {
            return {
                ...state,
                isPerformerFormTaskDetailsLoading: false,
            }
        }


        case types.GET_TASKS_COUNT: {
            return {
                ...state,
            }
        }
        case types.GET_TASKS_COUNT_SUCCESS: {
            return {
                ...state,
                tasksCount: { ...state.tasksCount, [action.stage]: action?.data?.count },
            }
        }
        case types.GET_TASKS_COUNT_FAIL: {
            return {
                ...state,
                isTaskDetailsLoading: false,
            }
        }

        case types.SET_TASKS_FILTERS_DATE: {
            return {
                ...state,
                tasksFilterDate: action?.data,
            }
        }

        case types.SET_TASKS_FILTERS_TYPE: {
            return {
                ...state,
                tasksFilterType: action?.data,
            }
        }

        case types.PATCH_TASK_FAIL: {
            return {
                ...state,
                isTaskPatchError: true,
            }
        }

        case types.TASK_PATCH_ERROR_CLEAR: {
            return {
                ...state,
                isTaskPatchError: false,
            }
        }


        case types.CONVERT_RESIDENTS: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.CONVERT_RESIDENTS_SUCCESS: {
            return {
                ...state,
                convertedResidents: {...action.data},
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.CONVERT_RESIDENTS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        case types.IMPORT_RESIDENTS: {
            return {
                ...state,
                isLoading: true,
                isAccessDenied: false,
            }
        }
        case types.IMPORT_RESIDENTS_SUCCESS: {
            return {
                ...state,
                redirectUrl: URL_LIST.residents,
                isLoading: false,
                isRequestError: false,
            }
        }
        case types.IMPORT_RESIDENTS_FAIL: {
            return {
                ...state,
                isLoading: false,
                isRequestError: true,
            }
        }

        default:
            return state
    }
}

export default reducer
