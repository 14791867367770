import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field, reduxForm, getFormValues, isValid, getFormSyncErrors } from 'redux-form'
import classNames from 'classnames'
import _ from 'lodash'
import Scroll from 'react-scroll'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import { getToken } from '../../../../__data__/utils'
import formStyle from '../../../../components/form/style.css'
import { getBuildingMetersSettings } from '../../actions/actions'
import { buildingSettingsDayRequired } from '../../../../components/form/validation'
import {
    MODERATOR_STORAGE_NAME,
    FORM_NAMES,
    URL_LIST, SETTINGS_WATER_LIST, SETTINGS_ELEC_LIST,
} from '../../../../__data__/constants'
import { makeBuildingMetersSettings } from '../../../../__data__/selectors'
import { makeBuildingSettingsMeterTypes } from '../../../../__data__/selectors/building'
import { RadioGroupMenu, CheckboxToggle } from '../../../../components/form'
import { makeBuildingName } from '../../../../__data__/selectors/buildings'

import DateInterval from './date-interval'
import submit from './submit'
import style from './style.css'
import { default as Action } from './action'
import { getInitialValues } from './utils'

function Component(props) {
    const { buildingMetersSettings, buildingName } = props

    const token = getToken(MODERATOR_STORAGE_NAME)
    const isWater = _.get(props, 'formValues.is_water')
    const isElec = _.get(props, 'formValues.is_elec')

    useEffect(() => {
        // Получаем данные
        const queryParams = queryString.parse(window.location.search)
        const id = _.get(queryParams, 'id')
        props.getBuildingMetersSettings(id)

        // Инициализируем форму
        const initFormData = getInitialValues(buildingMetersSettings)
        if (_.isEmpty) {
            props.initialize(initFormData)
        }
    }, [token, _.get(buildingMetersSettings, 'id')])

    return (
        <div className={formStyle.container}>
            <div className={formStyle.headerWrapper}>
                <div className={formStyle.header}>
                    <div className={formStyle.goListLinkWrapper}>
                        <div className={formStyle.goListLinkIcon}>L</div>
                        <Link className={formStyle.goListLink} to={URL_LIST.settingsBuildings}>Вернуться к настройкам</Link>
                    </div>
                </div>
                <h1 className={formStyle.title}>Укажите дни и типы показаний</h1>
                <div className={formStyle.titleBorder} />
            </div>
            <div className={formStyle.content}>
                { !props.isFormValid && <Scroll.Element name="fieldErrorScrollTarget" /> }
                <form className={style.form}>
                    <div className={classNames(style.fieldsSection)}>
                        <div className={formStyle.label}>В доме по адресу</div>
                        <div className={style.homeNumber}>{buildingName}</div>

                        <div className={style.delimiter} />
                        <div className={style.datesInterval}>
                            <Field
                                name='start_date'
                                component={DateInterval}
                                validate={[ buildingSettingsDayRequired ]}
                                formName={props.formName}
                                depFieldValue={_.get(props, 'formValues.end_date')}
                            />
                            <div className={style.datesDelimiter}>–</div>
                            <Field
                                name='end_date'
                                component={DateInterval}
                                validate={[ buildingSettingsDayRequired ]}
                                formName={props.formName}
                                depFieldValue={_.get(props, 'formValues.start_date')}
                            />
                            <div className={style.datesLabel}>число каждого месяца</div>
                        </div>
                    </div>

                    <div className={classNames(style.fieldsSection)}>
                        <Field
                            name='is_water'
                            component={CheckboxToggle}
                            type="checkbox"
                            id='is_water'
                            label='Вода'
                        />
                        { isWater && (
                            <div className={style.radioGroupWrapper}>
                                <RadioGroupMenu name='water_type' items={SETTINGS_WATER_LIST} />
                            </div>
                        )}
                    </div>
                    <div className={classNames(style.fieldsSection)}>
                        <Field
                            name='is_elec'
                            component={CheckboxToggle}
                            type="checkbox"
                            id='is_elec'
                            label='Электроэнергия'
                        />
                        { isElec && (
                            <div className={style.radioGroupWrapper}>
                                <RadioGroupMenu name='elec_type' items={SETTINGS_ELEC_LIST} />
                            </div>
                        )}
                    </div>
                    <div className={classNames(style.fieldsSection)}>
                        <Field
                            name='is_gas'
                            component={CheckboxToggle}
                            type="checkbox"
                            id='is_gas'
                            label='Газ'
                        />
                    </div>
                    <div className={classNames(style.fieldsSection)}>
                        <Field
                            name='is_heating'
                            component={CheckboxToggle}
                            type="checkbox"
                            id='is_heating'
                            label='Отопление'
                        />
                    </div>
                </form>
            </div>
            <div className={formStyle.actions}>
                <Action isValid={props.isFormValid} type="button" formName={props.formName} fieldErrors={props.fieldErrors} />
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const queryParams = queryString.parse(window.location.search)
    const id = _.get(queryParams, 'id')

    return createStructuredSelector({
        buildingMetersSettings: makeBuildingMetersSettings(),
        formValues: getFormValues(ownProps.formName),
        isFormValid: isValid(ownProps.formName),
        fieldErrors: getFormSyncErrors(ownProps.formName),
        meterTypes: makeBuildingSettingsMeterTypes(),
        buildingName: makeBuildingName(id),
    })
}

const mapDispatchToProps = {
    getBuildingMetersSettings,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.settingsMetersReadings,
    onSubmit: submit,
}) (Component)

export default compose(withConnect)(Component)
