import React from 'react'

function Component(profile) {
    const { avatar, firstName, lastName, company, position } = profile

    return (
        <div>
            <h2>Учётная запись активирована!</h2>
            <div>Войдите в Домком на компьютере</div>
            {avatar && <img src={avatar} alt='avatar' />}
            <div>{`${firstName} ${lastName}`}</div>
            <div>{position}</div>
            <div>{company.label}</div>
        </div>
    )
}

export default Component
