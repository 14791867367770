import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import classNames from 'classnames'

import style from './style.css'

const Component = props => {
    const { children, isDragging } = props

    return (
        <div className={classNames(style.container, isDragging && style.dragging)}>
            {children}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({

})

const mapDispatchToProps = {

}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
