import React from 'react'
import style from './style.css'


function Component(props) {
    const {handleStoryClick, story} = props

    return (
        // TODO: use the small + medium + large + original for loading images
        <div className={style.story} onClick={(event) => handleStoryClick(event, story)}>
            <img src={ story.cover.medium.file } className={style.image} alt="story-cover"/>
        </div>
    )
}

export default Component
