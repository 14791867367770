import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { makeObjectsList } from '../../../../../__data__/selectors/buildings'
import { getBuildingData } from '../../../../../__data__/actions/buildings'
import { HomeSelectInput } from '../../../../../components/form'
// import { TASK_PORCHES_ITEMS_ID, TASK_FLOORS_ITEMS_ID } from '../../../../../__data__/constants'

function Component(props) {
    const { input, meta, buildingsList, defaultOpen, formName, changeField, label } = props


    const handleChange = useCallback(() => {
        // changeField(formName, 'porchesToggle', TASK_PORCHES_ITEMS_ID.home)
        // changeField(formName, 'floorsToggle', TASK_FLOORS_ITEMS_ID.all)
        // changeField(formName, 'taskType', null)
        // changeField(formName, 'performer', null)
        // changeField(formName, 'porches', [])
        // changeField(formName, 'floors', [])
    }, [])

    return (
        <HomeSelectInput input={input} meta={meta} list={buildingsList} handleChange={handleChange} label={label} defaultOpen={defaultOpen} />
    )
}

const mapStateToProps = createStructuredSelector({
    buildingsList: makeObjectsList(),
})

const mapDispatchToProps = {
    getBuildingData,
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
