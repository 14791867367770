import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import RadioGroup from '../radio-group'
import Dropdown from '../dropdown-input-custom'

const useStyles = makeStyles((theme) => ({
    paginator: {
        position: 'fixed',
        left: '60px',
        bottom: 0,
        width: 'calc(100% - 60px)',
        backgroundColor: '#f7f8fa',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 0 16px -8px rgba(0,0,0,0.25)',
        paddingLeft: '60px',
        paddingRight: '60px',
        zIndex: '1',
    },
    blockSelectPage: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
    },
    paginatorDropDownPage: {
        width: '70px',
        marginLeft: '10px',
    },
    pageTool: {
        height: '32px',
        display: 'flex',
        alignSelf: 'center',
    },
    paginatorToolbar: {
        display: 'flex',
        width: '910px',
        padding: 0,
    },
    paginatorLable: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        fontFamily: 'Open Sans',
    },
    paginatorActions:{},
    paginatorButton: {
        flex: '0 0 auto',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '12px',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        border: 0,
        cursor: 'pointer',
        margin: 0,
        display: 'inline-flex',
        outline: 0,
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        borderRadius: 0,
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
    },
    paginatorArrow: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
        color: '#757678',
    },
    paginatorButtonPage: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
        color: '#757678',
    },
    paginatorNone: {
        opacity: 0.5,
        pointerEvents: 'none'
    }
}))

function Component(props) {
    const { tableObject, getDataPagination } = props

    const classes = useStyles()
    const [countPerPage, setCountPerPage] = useState({value: 10, label: '10'})
    const arrPerPage = [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
    ]

    const getElementByValue = (value) => arrPerPage.find((elem) => elem.value === value)

    useEffect(() => {
        setCountPerPage(tableObject.page_size ? getElementByValue(tableObject.page_size) : arrPerPage[1])
    }, [tableObject.number])

    const onChangeDropdown = (value) => {
        setCountPerPage(value)
        getDataPagination(1, value.value)
    }

    const changePage = (numberPage) => {
        getDataPagination(numberPage, countPerPage.value)
    }

    const prevPage = () => {
        getDataPagination(tableObject.number - 1, countPerPage.value)
    }

    const nextPage = () => {
        getDataPagination(tableObject.number + 1, countPerPage.value)
    }

    const getRadioBtn = () => {
        let arrRadioBtn = []
        const lastPage = Math.ceil(tableObject.count / countPerPage.value)
        if (lastPage > 5) {
            if (tableObject.number <= 3) {
                // покахываем от 1 до 4 и самую последнюю страницу
                arrRadioBtn[0] = {label:'1', value: 1, checked: tableObject.number === 1}
                arrRadioBtn[1] = {label:'2', value: 2, checked: tableObject.number === 2}
                arrRadioBtn[2] = {label:'3', value: 3, checked: tableObject.number === 3}
                if (lastPage > 5) {
                    arrRadioBtn[3] =  {label:'4...', value: 4, checked: tableObject.number === 4}
                } else {
                    arrRadioBtn[3] =  {label:'4', value: 4, checked: tableObject.number === 4}
                }
                arrRadioBtn[4] = {label: lastPage, value: lastPage, checked: false}
            } else if (tableObject.number >= lastPage - 3){
                //показываем самую первую кнопку и 4 последних
                arrRadioBtn[0] = {label:'1', value: 1, checked: false}
                arrRadioBtn[1] = {label: '...' + (lastPage - 3), value: lastPage - 3, checked: tableObject.number === lastPage - 3}
                arrRadioBtn[2] = {label:lastPage - 2, value: lastPage - 2, checked: tableObject.number === lastPage - 2}
                arrRadioBtn[3] = {label:lastPage - 1, value: lastPage - 1, checked: tableObject.number === lastPage - 1}
                arrRadioBtn[4] = {label:lastPage , value: lastPage , checked: tableObject.number === lastPage}
            }
            else {
                arrRadioBtn[0] = {label:'1', value: 1, checked: false}
                arrRadioBtn[1] = {label: '...' + (tableObject.number - 1), value: tableObject.number -1, checked: false}
                arrRadioBtn[2] = {label: tableObject.number, value: tableObject.number, checked: true}
                arrRadioBtn[3] = {label: (tableObject.number + 1) + '...', value: tableObject.number + 1, checked: false}
                arrRadioBtn[4] = {label: lastPage, value: lastPage, checked: false}
            }
        } else if (lastPage <= 5) {
            for(let i = 0; i < lastPage; i++){
                arrRadioBtn[i] = {label: i+1, value: i+1, checked: tableObject.number === i+1}
            }
        } else if (lastPage === 1) {
            arrRadioBtn = []
        }

        return arrRadioBtn
    }

    return (
        <div className={classes.paginator}>
            <div className={classes.paginatorToolbar}>
                <div className={classes.blockSelectPage}>
                    <Dropdown
                        options={arrPerPage}
                        className={classes.paginatorDropDownPage}
                        onChange={onChangeDropdown}
                        value={countPerPage}
                        listUp={true}
                        textBeforeValue={'На странице'}
                    />
                </div>
                <div className={classes.pageTool}>
                    <RadioGroup changePage={changePage} name={'paginatorPage'} items={getRadioBtn()} />
                </div>
                <div className={classes.paginatorActions}>
                    <button  className={classNames(classes.paginatorButton, !tableObject.previous && classes.paginatorNone)} onClick={prevPage}>
                        <svg className={classes.paginatorArrow} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
                        </svg>
                    </button>
                    <button className={classNames(classes.paginatorButton, !tableObject.next && classes.paginatorNone)} onClick={nextPage}>
                        <svg className={classes.paginatorArrow} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Component
