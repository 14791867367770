import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeBuildingFlats = () =>
    createSelector(app, slice => {
        // Пока там всегда 'flat', но заранее отфильтровал
        const flats = _.filter(_.get(slice, 'building.units'), i => i.unit_type === 'flat')
        return _.map(flats, i => i.object)
    })

export const makeBuildingPorches = () =>
    createSelector(app, slice => {
        return _.get(slice, 'building.porches')
    })

export const makeBuildingPipes = () =>
    createSelector(app, slice => {
        const pipes = _.get(slice, 'building.pipes')

        return _.map(pipes, item => {
            const flats = _.map(item.flats, i => i.label)
            return ({
                id: item.id,
                name: item.name,
                details: `квартиры:\n${flats.join(', ')}`
            })
        })
    })

export const makeBuildingFloorList = () =>
    createSelector(app, slice => {
        return _.get(slice, 'building.floors')
    })

export const makeBuildingSettingsMeterTypes = () =>
    createSelector(app, slice => {
        return _.get(slice.app, 'buildingsSettings.reading_types')
    })

export const makeLastMonthMeters = () =>
    createSelector(app, slice => {
        return _.get(slice, 'metersLastResult.last_result')
    })
        
export const makeBuildingSettingsFlats = (state, buildingId) =>
    createSelector(app, () => {
        const buildings = _.get(state.app, 'buildings')
        const building = _.find(buildings, i => i.id === buildingId)
        return _.get(building, 'flats')
    })()

export const makePorchFlats = () =>
    createSelector(app, slice => {
        const flats = _.filter(_.get(slice, 'porch.units'), i => i.unit_type === 'flat')
        return _.map(flats, i => i.object)
    })

export const makePorchFloors = () =>
    createSelector(app, slice => {
        return _.get(slice, 'porch.floors')
    })

export const makePorchPipes = () =>
    createSelector(app, slice => {
        const pipes = _.get(slice, 'porch.pipes')

        return _.map(pipes, item => {
            const flats = _.map(item.flats, i => i.label)
            return ({
                id: item.id,
                name: item.name,
                details: `квартиры:\n${flats.join(', ')}`
            })
        })
    })
