import { createSelector } from 'reselect'
import _ from 'lodash'

import { getBuildingString, getNavigationBuildingString } from '../utils'

const app = state => _.get(state, 'app', {})

export const makeIsHomeSelectActive = () =>
    createSelector(app, slice => {
        return _.get(slice, 'isHomeSelectActive', false)
    })

export const makeIsBuildingsListRS = () =>
    createSelector(app, slice => {
        return _.get(slice, 'isBuildingListRQ', false)
    })

export const makeBuildingsList = () => (
    createSelector(app, slice => {
        const buildings = _.get(slice, 'buildings');

        return buildings && Object.values(buildings).reduce((accum, current) => {
            return accum.set(current.id, current);
        }, new Map())
    })
)

export const makeObjectsList = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'buildings')

        const list = []
        _.forEach(buildings, item => {
            const label = getBuildingString(item)
            if (item.is_all_porches) {
                list.push({ value: item.id, label, type: 'building' })
            } else {
                _.forEach(item.available_porches, porch => {
                    list.push({
                        value: porch.id,
                        label: `${label} (п. ${porch.number})`,
                        type: 'porch',
                        buildingId: item.id,
                    })
                })

            }
        })
        return list
    })

export const makeBuildingList = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'buildings')
        return _.map(buildings, item => ({ id: item.id, label: getNavigationBuildingString(item) }))
    })

export const makeIsAllBuildings = () =>
    createSelector(app, slice => {
        const checkedBuildingsId = _.sortBy(_.get(slice, 'checkedBuildings', []))

        const buildings = _.get(slice, 'buildings', [])
        const buildingsId = _.sortBy(_.map(buildings, i => i.id + ''))

        return _.isEqual(checkedBuildingsId, buildingsId)
    })

export const makeBuildingName = (buildingId) =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'buildings')
        if (!buildings) return ''
        const building = _.find(buildings, i => i.id === buildingId)
        const isBuildingExist = _.get(building, 'street')
        if(!isBuildingExist) return 'Такого здания больше нет'
        return getBuildingString(building)
    })
