import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeStories = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'storyList')
        return data

    })

export const makeStoriesFull = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'storyList')

        const stories = data?.map((story) => {
            return {
                defaultInterval: story.duration,
                textBtn: story.title,
                id: story.id,
                cover: story.cover.large.file,
                stories: story?.frames?.map((frame, index) => {
                    let duration = frame.duration;
                    if(index === 0){
                        duration = duration + 1000
                    }
                    return {
                        duration: duration,
                        header: frame.title,
                        text: frame.description ,
                        url: frame['background_content']['large']['file'],
                        type:  frame['background_content']['type'] === 'video' ? 'video' : 'image',
                    }
                })
            }


        })

        return stories

    })

export const makeCurrentStoryIndex = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'currentStory')
        const allData = _.get(slice, 'storyList')
        const selectedItem = allData?.map(function(e) { return e?.id; })?.indexOf(data?.id);

        return selectedItem
    })
