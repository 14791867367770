import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { reduxForm, getFormValues } from 'redux-form'
import queryString from 'query-string'
import _ from 'lodash'
import Scroll, { Element } from 'react-scroll'

import { FormConstructor } from '../../../../components/form'
import { SETTINGS_OBJECTS_FORM_FIELDS_ERRORS, FORM_NAMES, URL_LIST } from '../../../../__data__/constants'
import { getEmployee } from '../../actions/staff-actions'
import { ScreenTabs } from '../../../../components/form'
import { makeEmployeeFormOrganisations } from '../../../../__data__/selectors'
import { getEmployeeFormOrganisations } from '../../actions/actions'
import { getSettingsObject } from '../../actions/staff-actions'
import { makeBuildingsWithGroups, makeObjectFormInitialData } from '../../selectors/staff-selectors'
import style from '../style.css'

import PassportFields from './passport-fields'
import ReadingsFields from './readings-fields'
import CamerasFields from './cameras-fields'
import submit from './submit'

const scroll = Scroll.animateScroll

const tabs = [
    {
        title: 'Паспорт',
        value: 'details',
        enabled: true,
    },
    {
        title: 'Типы показаний',
        value: 'readings',
        enabled: true,
    }, {
        title: 'Камеры',
        value: 'cameras',
        enabled: true,
    },
]

function Component(props) {
    const {
        initialize,
        initialData,
        formValues,
        getSettingsObject,
    } = props

    const queryParams = queryString.parse(window.location.search)
    const buildingId = _.get(queryParams, 'id')
    const screen = _.get(queryParams, 'screen')

    useEffect(() => {
        if (screen) {
            if (screen === 'readings') scroll.scrollTo(300)
            if (screen === 'cameras') scroll.scrollTo(900)
        }
    }, [screen])

    useEffect(() => {
        getSettingsObject(buildingId)
    }, [buildingId])

    useEffect(() => {
        if (initialData) {
            initialize(initialData)
        }
    }, [_.get(initialData, 'startDate')])

    const defaultTScreen = _.get(queryParams, 'screen')

    const [selectTab, setSelectTab] = React.useState(defaultTScreen || 'details')

    const handleTabClick = (selectTab) => {
        setSelectTab(selectTab)
    }

    return (
        <FormConstructor
            pageTitle='Укажите настройки объектов'
            goBackLinkText='Объекты'
            goBackLinkUrl={URL_LIST.settingsBuildings}
            columnCount='6'
            buttonText='Сохранить изменения'
            formName={FORM_NAMES.settingsObjectsForm}
            onSubmit={submit}
            contentTop={true}
            fieldErrors={SETTINGS_OBJECTS_FORM_FIELDS_ERRORS}
        >
            <ScreenTabs useScroll={true} items={tabs} handleClick={handleTabClick} activeItem={selectTab} />
            <Element name="details">
                <PassportFields formValues={formValues} />
            </Element>
            <Element className={style.section} name="readings">
                <ReadingsFields formValues={formValues} />
            </Element>
            <Element className={style.section} name="cameras">
                <CamerasFields buildingId={buildingId} />
            </Element>
        </FormConstructor>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
    initialData: makeObjectFormInitialData(),
    organisationList: makeEmployeeFormOrganisations(),
    buildingsComplex: makeBuildingsWithGroups(),
})

const mapDispatchToProps = {
    getEmployeeFormOrganisations,
    getEmployee,
    getSettingsObject,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.settingsObjectsForm,
    onSubmit: submit,
}) (Component)

export default compose(withConnect)(Component)
