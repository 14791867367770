import React, { useEffect } from 'react'
import { reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { FormConstructor } from '../../../../../components/form'
import { FORM_NAMES, EMPLOYEE_FORM_FIELDS_ERRORS, URL_LIST } from '../../../../../__data__/constants'
import { ScreenTabs } from '../../../../../components/form'
import PersonalInfoFields from '../componets/personal-info-fields'
import { getEmployeeFormOrganisations } from '../../../actions/actions'
import { makeEmployeeFormOrganisations } from '../../../../../__data__/selectors'

import submit from './submit'
import {isUserAdmin} from "../../../../../__data__/utils";

const tabs = [
    {
        title: '1. Личные данные',
        value: 'personalInfo',
        enabled: true
    },
    {
        title: '2. Разделы',
        value: 'sectionsAccess',
        enabled: false,
        textBlock: 'чтобы продолжить, заполните личные данные'
    },
    {
        title: '3. Объекты',
        value: 'objectAccess',
        enabled: false,
        textBlock: 'чтобы продолжить, заполните личные данные'
    },
    isUserAdmin && {
        title: '4. Сервисы',
        value: 'services',
        enabled: false,
        textBlock: 'чтобы продолжить, заполните личные данные (затем выберите "2. Разделы -> Заявки -> Быть исполнителем")'
    },
]

function Component(props) {
    const { organisationList, getOrganisationList } = props

    useEffect(() => {
        getOrganisationList()
    }, [])

    useEffect(() => {
        document.body.style.overflow = "unset"
        document.body.style.height = "auto"
    }, [])

    return (
        <FormConstructor
            pageTitle='Какие данные у нового сотрудника?'
            goBackLinkText='Вернуться к списку сотрудников'
            goBackLinkUrl={URL_LIST.settingsEmployeesPermissions}
            columnCount='4'
            buttonText='+ Добавить сотрудника'
            formName={FORM_NAMES.settingsAddEmployee}
            onSubmit={submit}
            contentTop={true}
            fieldErrors={EMPLOYEE_FORM_FIELDS_ERRORS}
        >
            <ScreenTabs useScroll={false} items={tabs} handleClick={() => null} activeItem='personalInfo' />
            <PersonalInfoFields organisationList={organisationList} />
        </FormConstructor>
    )
}

const mapStateToProps = () => createStructuredSelector({
    organisationList: makeEmployeeFormOrganisations(),
})

const mapDispatchToProps = {
    getOrganisationList: getEmployeeFormOrganisations,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.settingsAddEmployee,
    onSubmit: submit,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
}) (Component)

export default compose(withConnect)(Component)
