import React, { useEffect } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import { makeCameraList, makeIsCameraListChanged } from '../../__data__/selectors/monitoring'
import { getCameraList } from '../../__data__/actions/monitoring'
import { URL_LIST } from '../../__data__/constants'
import { makeCheckedBuildings } from '../../__data__/selectors/common'

import CameraList from './components/camera-list'
import style from './style.css'
import {checkWritePermissionForSection} from "../../__data__/actions/permissions";

function Cameras(props) {
    const { getCameraList, cameraList, checkedBuildings, isCameraListChanged } = props
    const isAvailableByPerms = checkWritePermissionForSection('Камеры')
    const buttonText = '+ Добавить камеру'

    useEffect(() => {
        checkedBuildings && getCameraList(checkedBuildings)
    }, [checkedBuildings])

    useEffect(() => {
        isCameraListChanged && checkedBuildings && getCameraList(checkedBuildings)
    }, [isCameraListChanged])

    useEffect(() => {
        document.body.style.overflow = "unset"
        document.body.style.height = "auto"
    }, [])

    return (
        <div className={style.container}>
            <div className={style.headerWrapper}>
                <div className={style.header}>
                    <div className={style.pageTitleStub} />
                    {!isAvailableByPerms ? (
                        <Link to={undefined} className={style.disabledLink}>{buttonText}</Link>
                    ) : (
                        <Link to={URL_LIST.createCameraForm} className={style.addCameraLink}>{buttonText}</Link>
                    )}
                </div>
            </div>
            <div className={style.list}>
                {_.map(cameraList, i => (
                    <CameraList
                        key={i.id}
                        id={i.id}
                        name={i.name}
                        link={i.link}
                    />)
                )}
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    cameraList: makeCameraList(),
    checkedBuildings: makeCheckedBuildings(),
    isCameraListChanged: makeIsCameraListChanged(),
})

const mapDispatchToProps = {
    getCameraList,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Cameras)
