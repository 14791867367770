export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'
export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL'

export const SEND_ACCOUNT_CONFIRMATION_CODE = 'SEND_ACCOUNT_CONFIRMATION_CODE'
export const SEND_ACCOUNT_CONFIRMATION_CODE_SUCCESS = 'SEND_ACCOUNT_CONFIRMATION_CODE_SUCCESS'
export const SEND_ACCOUNT_CONFIRMATION_CODE_FAIL = 'SEND_ACCOUNT_CONFIRMATION_CODE_FAIL'

export const SET_ACCOUNT_CONFIRMATION_CODE = 'SET_ACCOUNT_CONFIRMATION_CODE'
export const SET_ACCOUNT_CONFIRMATION_CODE_SUCCESS = 'SET_ACCOUNT_CONFIRMATION_CODE_SUCCESS'
export const SET_ACCOUNT_CONFIRMATION_CODE_FAIL = 'SET_ACCOUNT_CONFIRMATION_CODE_FAIL'

export const SET_PASSWORD = 'SET_PASSWORD'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAIL'

export const CLEAR_IS_PASSWORD_CHANGED = 'CLEAR_IS_PASSWORD_CHANGED'

export const GET_FILTERED_ENTRY = 'GET_FILTERED_ENTRY'
export const GET_FILTERED_ENTRY_SUCCESS = 'GET_FILTERED_ENTRY_SUCCESS'
export const GET_FILTERED_ENTRY_FAIL = 'GET_FILTERED_ENTRY_FAIL'

export const GET_UNITS = 'GET_UNITS'
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS'
export const GET_UNITS_FAIL = 'GET_UNITS_FAIL'

export const CLEAR_REDIRECT_URL = 'CLEAR_REDIRECT_URL'

export const SET_GLOBAL_BUILDING = 'SET_GLOBAL_BUILDING'

export const SET_HOME_SELECT = 'SET_HOME_SELECT'

export const GET_RESIDENTS_LIST = 'GET_RESIDENTS_LIST'
export const GET_RESIDENTS_LIST_SUCCESS = 'GET_RESIDENTS_LIST_SUCCESS'
export const GET_RESIDENTS_LIST_FAIL = 'GET_RESIDENTS_LIST_FAIL'

export const SET_RESIDENTS_FILTER = 'SET_RESIDENTS_FILTER'

export const GET_BUILDING_LIST = 'GET_BUILDING_LIST'
export const GET_BUILDING_LIST_SUCCESS = 'GET_BUILDING_LIST_SUCCESS'
export const GET_BUILDING_LIST_FAIL = 'GET_BUILDING_LIST_FAIL'

export const GET_BUILDING_DATA = 'GET_DATA_BUILDING'
export const GET_BUILDING_DATA_SUCCESS = 'GET_BUILDING_DATA_SUCCESS'
export const GET_BUILDING_DATA_FAIL = 'GET_BUILDING_DATA_FAIL'

export const GET_PORCH_DATA = 'GET_PORCH_DATA'
export const GET_PORCH_DATA_SUCCESS = 'GET_PORCH_DATA_SUCCESS'
export const GET_PORCH_DATA_FAIL = 'GET_PORCH_DATA_FAIL'

export const GET_BUILDING_SETTINGS = 'GET_BUILDING_SETTINGS'
export const GET_BUILDING_SETTINGS_SUCCESS = 'GET_BUILDING_SETTINGS_SUCCESS'
export const GET_BUILDING_SETTINGS_FAIL = 'GET_BUILDING_SETTINGS_FAIL'

export const GET_BUILDINGS_SETTINGS = 'GET_BUILDINGS_SETTINGS'
export const GET_BUILDINGS_SETTINGS_SUCCESS = 'GET_BUILDINGS_SETTINGS_SUCCESS'
export const GET_BUILDINGS_SETTINGS_FAIL = 'GET_BUILDINGS_SETTINGS_FAIL'

export const CREATE_RESIDENT = 'CREATE_RESIDENT'
export const CREATE_RESIDENT_SUCCESS = 'CREATE_RESIDENT_SUCCESS'
export const CREATE_RESIDENT_FAIL = 'CREATE_RESIDENT_FAIL'

export const EDIT_RESIDENT = 'EDIT_RESIDENT'
export const EDIT_RESIDENT_SUCCESS = 'EDIT_RESIDENT_SUCCESS'
export const EDIT_RESIDENT_FAIL = 'EDIT_RESIDENT_FAIL'

export const DELETE_RESIDENT = 'DELETE_RESIDENT'
export const DELETE_RESIDENT_SUCCESS = 'DELETE_RESIDENT_SUCCESS'
export const DELETE_RESIDENT_FAIL = 'DELETE_RESIDENT_FAIL'

export const BLOCK_RESIDENT = 'BLOCK_RESIDENT'
export const BLOCK_RESIDENT_SUCCESS = 'BLOCK_RESIDENT_SUCCESS'
export const BLOCK_RESIDENT_FAIL = 'BLOCK_RESIDENT_FAIL'

export const GET_RESIDENT = 'GET_RESIDENT'
export const GET_RESIDENT_SUCCESS = 'GET_RESIDENT_SUCCESS'
export const GET_RESIDENT_FAIL = 'GET_RESIDENT_FAIL'

export const GET_NEWS_LIST = 'GET_NEWS_LIST'
export const GET_NEWS_LIST_SUCCESS = 'GET_NEWS_LIST_SUCCESS'
export const GET_NEWS_LIST_FAIL = 'GET_NEWS_LIST_FAIL'

export const GET_NEWS = 'GET_NEWS'
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS'
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL'

export const GET_NEXT_NEWS_LIST = 'GET_NEXT_NEWS_LIST'
export const GET_NEXT_NEWS_LIST_SUCCESS = 'GET_NEXT_NEWS_LIST_SUCCESS'
export const GET_NEXT_NEWS_LIST_FAIL = 'GET_NEXT_NEWS_LIST_FAIL'

export const CREATE_NEWS = 'CREATE_NEWS'
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS'
export const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL'

export const EDIT_NEWS = 'EDIT_NEWS'
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS'
export const EDIT_NEWS_FAIL = 'EDIT_NEWS_FAIL'

export const DELETE_NEWS = 'DELETE_NEWS'
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS'
export const DELETE_NEWS_FAIL = 'DELETE_NEWS_FAIL'

export const GET_METERS_LIST = 'GET_METERS_LIST'
export const GET_METERS_LIST_SUCCESS = 'GET_METERS_LIST_SUCCESS'
export const GET_METERS_LIST_FAIL = 'GET_METERS_LIST_FAIL'
export const GET_METERS_LAST_MONTH = 'GET_METERS_LAST_MONTH'
export const GET_METERS_LAST_MONTH_SUCCESS = 'GET_METERS_LAST_MONTH_SUCCESS'
export const GET_METERS_LAST_MONTH_FAIL = 'GET_METERS_LAST_MONTH_FAIL'

export const SEND_METERS = 'SEND_METERS'
export const SEND_METERS_SUCCESS = 'SEND_METERS_SUCCESS'
export const SEND_METERS_FAIL = 'SEND_METERS_FAIL'

export const SET_CAROUSEL = 'SET_CAROUSEL'
export const SET_STORI = 'SET_STORI'

export const GET_STORI_LIST = 'GET_STORI_LIST'
export const GET_STORI_LIST_SUCCESS = 'GET_STORI_LIST_SUCCESS'
export const GET_STORI_LIST_FAIL = 'GET_STORI_LIST_FAIL'

export const GET_STORI_FULL = 'GET_STORI_FULL'
export const GET_STORI_FULL_SUCCESS = 'GET_STORI_FULL_SUCCESS'
export const GET_STORI_FULL_FAIL = 'GET_STORI_FULL_FAIL'


export const GET_SETTINGS_READING = 'GET_SETTINGS_READING'
export const GET_SETTINGS_READING_SUCCESS = 'GET_SETTINGS_READING_SUCCESS'
export const GET_SETTINGS_READING_FAIL = 'GET_SETTINGS_READING_FAIL'

export const GET_BUILDING_METERS_SETTINGS = 'GET_BUILDING_METERS_SETTINGS'
export const GET_BUILDING_METERS_SETTINGS_SUCCESS = 'GET_BUILDING_METERS_SETTINGS_SUCCESS'
export const GET_BUILDING_METERS_SETTINGS_FAIL = 'GET_BUILDING_METERS_SETTINGS_FAIL'

export const SET_BUILDING_METERS_SETTINGS = 'SET_BUILDING_METERS_SETTINGS'
export const SET_BUILDING_METERS_SETTINGS_SUCCESS = 'SET_BUILDING_METERS_SETTINGS_SUCCESS'
export const SET_BUILDING_METERS_SETTINGS_FAIL = 'SET_BUILDING_METERS_SETTINGS_FAIL'

export const GET_EMPLOYEE_FORM_ORGANISATIONS = 'GET_EMPLOYEE_FORM_ORGANISATIONS'
export const GET_EMPLOYEE_FORM_ORGANISATIONS_SUCCESS = 'GET_EMPLOYEE_FORM_ORGANISATIONS_SUCCESS'
export const GET_EMPLOYEE_FORM_ORGANISATIONS_FAIL = 'GET_EMPLOYEE_FORM_ORGANISATIONS_FAIL'

export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const CLEAR_EMPLOYEE = 'CLEAR_EMPLOYEE'
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS'
export const CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL'

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL'

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL'

export const BLOCK_EMPLOYEE = 'BLOCK_EMPLOYEE'
export const BLOCK_EMPLOYEE_SUCCESS = 'BLOCK_EMPLOYEE_SUCCESS'
export const BLOCK_EMPLOYEE_FAIL = 'BLOCK_EMPLOYEE_FAIL'

export const GET_CAMERA_LIST = 'GET_CAMERA_LIST'
export const GET_CAMERA_LIST_SUCCESS = 'GET_CAMERA_LIST_SUCCESS'
export const GET_CAMERA_LIST_FAIL = 'GET_CAMERA_LIST_FAIL'


export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'

export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL'

export const SET_AVATAR = 'SET_AVATAR'
export const SET_AVATAR_SUCCESS = 'SET_AVATAR_SUCCESS'
export const SET_AVATAR_FAIL = 'SET_AVATAR_FAIL'

export const DELETE_AVATAR = 'DELETE_AVATAR'
export const DELETE_AVATAR_SUCCESS = 'DELETE_AVATAR_SUCCESS'
export const DELETE_AVATAR_FAIL = 'DELETE_AVATAR_FAIL'

export const LOGGED_SMS = 'LOGGED_SMS'
export const LOGGED_SMS_SUCCESS = 'LOGGED_SMS_SUCCESS'
export const LOGGED_SMS_FAIL = 'LOGGED_SMS_FAIL'

export const LOGGED_CONFIRMATION_CODE = 'LOGGED_CONFIRMATION_CODE'
export const LOGGED_CONFIRMATION_CODE_SUCCESS = 'LOGGED_CONFIRMATION_CODE_SUCCESS'
export const LOGGED_CONFIRMATION_CODE_FAIL = 'LOGGED_CONFIRMATION_CODE_FAIL'

export const FETCH_GET_MESSAGING_DEVICE_TOKEN = 'FETCH_GET_MESSAGING_DEVICE_TOKEN'
export const FETCH_GET_MESSAGING_DEVICE_TOKEN_SUCCESS = 'FETCH_GET_MESSAGING_DEVICE_TOKEN_SUCCESS'
export const FETCH_GET_MESSAGING_DEVICE_TOKEN_FAIL = 'FETCH_GET_MESSAGING_DEVICE_TOKEN_FAIL'

export const FETCH_REPEAT_MESSAGING_DEVICE_TOKEN = 'FETCH_REPEAT_MESSAGING_DEVICE_TOKEN'
export const FETCH_REPEAT_MESSAGING_DEVICE_TOKEN_SUCCESS = 'FETCH_REPEAT_MESSAGING_DEVICE_TOKEN_SUCCESS'
export const FETCH_REPEAT_MESSAGING_DEVICE_TOKEN_FAIL = 'FETCH_REPEAT_MESSAGING_DEVICE_TOKEN_FAIL'

export const FETCH_SEND_MESSAGING_DEVICE_TOKEN = 'FETCH_SEND_MESSAGING_DEVICE_TOKEN'
export const FETCH_SEND_MESSAGING_DEVICE_TOKEN_SUCCESS = 'FETCH_SEND_MESSAGING_DEVICE_TOKEN_SUCCESS'
export const FETCH_SEND_MESSAGING_DEVICE_TOKEN_FAIL = 'FETCH_SEND_MESSAGING_DEVICE_TOKEN_FAIL'

export const FETCH_PUSH_NOTIFICATION = 'FETCH_PUSH_NOTIFICATION'

export const FETCH_DELETE_NOTIFICATION = 'FETCH_DELETE_NOTIFICATION'

export const FETCH_DELETE_ALL_NOTIFICATION = 'FETCH_DELETE_ALL_NOTIFICATION'

export const GET_TASKS = 'GET_TASKS'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL'

export const GET_NEXT_TASKS = 'GET_NEXT_TASKS'
export const GET_NEXT_TASKS_SUCCESS = 'GET_NEXT_TASKS_SUCCESS'
export const GET_NEXT_TASKS_FAIL = 'GET_NEXT_TASKS_FAIL'

export const GET_TASKS_COUNT = 'GET_TASKS_COUNT'
export const GET_TASKS_COUNT_SUCCESS = 'GET_TASKS_COUNT_SUCCESS'
export const GET_TASKS_COUNT_FAIL = 'GET_TASKS_COUNT_FAIL'

export const SAVE_TASKS_TO_STORE_LIST = 'SAVE_TASKS_TO_STORE_LIST'

export const GET_SERVICES = 'GET_SERVICES'
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAIL = 'GET_SERVICES_FAIL'

export const GET_EXECUTORS = 'GET_EXECUTORS'
export const GET_EXECUTORS_SUCCESS = 'GET_EXECUTORS_SUCCESS'
export const GET_EXECUTORS_FAIL = 'GET_EXECUTORS_FAIL'

export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL'

export const GET_TASK_DETAILS = 'GET_TASK_DETAILS'
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS'
export const GET_TASK_DETAILS_FAIL = 'GET_TASK_DETAILS_FAIL'

export const GET_PERFORMER_FORM_TASK_DETAILS = 'GET_PERFORMER_FORM_TASK_DETAILS'
export const GET_PERFORMER_FORM_TASK_DETAILS_SUCCESS = 'GET_PERFORMER_FORM_TASK_DETAILS_SUCCESS'
export const GET_PERFORMER_FORM_TASK_DETAILS_FAIL = 'GET_PERFORMER_FORM_TASK_DETAILS_FAIL'

export const PATCH_TASK = 'PATCH_TASK'
export const PATCH_TASK_SUCCESS = 'PATCH_TASK_SUCCESS'
export const PATCH_TASK_FAIL = 'PATCH_TASK_FAIL'
export const TASK_PATCH_ERROR_CLEAR = 'TASK_PATCH_ERROR_CLEAR'

export const SET_TASKS_FILTERS_DATE = 'SET_TASKS_FILTERS_DATE'
export const SET_TASKS_FILTERS_TYPE = 'SET_TASKS_FILTERS_TYPE'

export const CREATE_CAMERA = 'CREATE_CAMERA'
export const CREATE_CAMERA_SUCCESS = 'CREATE_CAMERA_SUCCESS'
export const CREATE_CAMERA_FAIL = 'CREATE_CAMERA_FAIL'

export const GET_CAMERA = 'GET_CAMERA'
export const GET_CAMERA_SUCCESS = 'GET_CAMERA_SUCCESS'
export const GET_CAMERA_FAIL = 'GET_CAMERA_FAIL'

export const EDIT_CAMERA = 'EDIT_CAMERA'
export const EDIT_CAMERA_SUCCESS = 'EDIT_CAMERA_SUCCESS'
export const EDIT_CAMERA_FAIL = 'EDIT_CAMERA_FAIL'

export const DELETE_CAMERA = 'DELETE_CAMERA'
export const DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS'
export const DELETE_CAMERA_FAIL = 'DELETE_CAMERA_FAIL'

export const GET_BUILDING_PASSPORT = 'GET_BUILDING_PASSPORT'
export const GET_BUILDING_PASSPORT_SUCCESS = 'GET_BUILDING_PASSPORT_SUCCESS'
export const GET_BUILDING_PASSPORT_FAIL = 'GET_BUILDING_PASSPORT_FAIL'

export const CONVERT_RESIDENTS = 'CONVERT_RESIDENTS'
export const CONVERT_RESIDENTS_SUCCESS = 'CONVERT_RESIDENTS_SUCCESS'
export const CONVERT_RESIDENTS_FAIL = 'CONVERT_RESIDENTS_FAIL'

export const IMPORT_RESIDENTS = 'IMPORT_RESIDENTS'
export const IMPORT_RESIDENTS_SUCCESS = 'IMPORT_RESIDENTS_SUCCESS'
export const IMPORT_RESIDENTS_FAIL = 'IMPORT_RESIDENTS_FAIL'

