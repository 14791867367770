import React, { useCallback, useState, useEffect } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import AnimateHeight from 'react-animate-height'
import classNames from 'classnames'

import { makeNotificationList, makeNotificationGrowIndex } from '../../__data__/selectors/notifications'
import { deleteAllNotification } from '../../__data__/actions/notifications'

import style from './style.css'
import Item from './item'

const sizes = {
    0: 0,
    1: 74,
    2: 156,
    3: 242,
    4: 'auto',
}

function Component(props) {
    const { notificationList, notificationGrowIndex, deleteAllNotification } = props
    const notificationSize = _.size(notificationList)
    const notificationHeight = notificationSize < 4 ? sizes[notificationSize] : ((notificationSize * 74) + ((notificationSize - 1) * 8))

    const [isContainerHidden, setHidden] = useState(false)

    const onHideAllClick = useCallback(() => {
        setHidden(true)
        deleteAllNotification()
    }, [])

    useEffect(() => {
        if (_.isEmpty(notificationSize)) {setHidden(false)}
    }, [notificationSize])

    return (
        <div className={classNames(style.container, isContainerHidden && style.hidden)}>
            <AnimateHeight id='notifications' duration={500} delay={300} height={notificationHeight}>
                <div className={style.list}>
                    <div className={classNames(style.dummyItem, notificationGrowIndex === -1 && style.dummyItemGrow)} />
                    { _.map(notificationList, (i, key) => (
                        <Item
                            key={i.id}
                            message={i.message}
                            reference={i.ref}
                            id={i.id}
                            sentBy={i.sentBy}
                            subtype={i.subtype}
                            type={i.type}
                            isGrow={key === notificationGrowIndex}
                        />
                    ))}
                </div>
            </AnimateHeight>
            { notificationSize > 1 && <div onClick={onHideAllClick} className={style.hideAllButton}>Скрыть все</div> }
        </div>
    )
}

const mapStateToProps = () => createStructuredSelector({
    notificationList: makeNotificationList(),
    notificationGrowIndex: makeNotificationGrowIndex(),
})

const mapDispatchToProps = {
    deleteAllNotification,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
