import React, {Fragment, useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {reduxForm, getFormValues, Field} from 'redux-form'
import queryString from 'query-string'
import _ from 'lodash'

import { getNews } from '../../__data__/actions/news'
import { makeEditNewsInitialData } from '../../__data__/selectors/news-form'
import formStyle from '../../theme/form.css'
import { FormConstructor, RadioGroupMenu, TextArea, TextInput } from '../../components/form'
import {
    FORM_NAMES,
    URL_LIST, NEWS_FORM_FIELDS_ERRORS, NEWS_TYPES_LIST,
} from '../../__data__/constants'

import submit from './submit'
import {
    filesValidation,
    formDescriptionValidateMax,
    formDescriptionValidateMin,
    formTitleValidateMax,
    formTitleValidateMin,
} from '../../components/form/validation'
import UploadComponent from '../../components/s3/uploader'
import { getEmployee } from '../settings/actions/staff-actions'
import { getRepresentableTarget } from '../../__data__/utils'

function Component(props) {
    const { getNews, initialize, formValues, initialNewsData, formName } = props

    const queryParams = queryString.parse(window.location.search)
    const newsId = _.get(queryParams, 'id')

    const target = _.get(formValues, 'target')
    const targetText = target ? getRepresentableTarget(target) : 'Вычисляю адресатов'

    useEffect(() => {
        initialize(initialNewsData)
    }, [_.get(initialNewsData, 'newsTitle')])

    useEffect(() => {
        getNews(newsId)
    }, [newsId])

    return (
        <FormConstructor
            pageTitle='Внести изменения в объявление'
            goBackLinkText='Вернуться к объявлениям'
            goBackLinkUrl={URL_LIST.news}
            columnCount='5'
            buttonText='Внести изменения и опубликовать'
            formName={formName}
            onSubmit={submit}
            fieldErrors={NEWS_FORM_FIELDS_ERRORS}
        >
            <Fragment>
                <div className={formStyle.groupSection}>
                    {targetText}
                </div>
                    <div className={formStyle.groupSection}>
                        <Field
                            name="newsTitle"
                            component={TextInput}
                            type="text"
                            label="Заголовок объявления"
                            value=''
                            placeholder=""
                            validate={[formTitleValidateMin, formTitleValidateMax]}
                        />
                        <Field
                            name="newsDescription"
                            component={TextArea}
                            type="text"
                            label="Содержание"
                            value=''
                            placeholder=""
                            validate={[formDescriptionValidateMin, formDescriptionValidateMax]}
                        />
                        <RadioGroupMenu name='newsType' items={NEWS_TYPES_LIST} />
                    </div>
                    <div className={formStyle.groupSection}>
                        <Field
                            name="files"
                            component={UploadComponent}
                            value=''
                            formName={formName}
                            validate={[filesValidation]}
                        />
                    </div>
            </Fragment>
        </FormConstructor>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
    initialNewsData: makeEditNewsInitialData(),
})

const mapDispatchToProps = {
    getNews,
    getEmployee,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.editNews,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
