import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { reduxForm, Field } from 'redux-form'

import {
    FORM_NAMES, URL_LIST,
    CAMERA_FORM_FIELDS_ERRORS,
} from '../../__data__/constants'
import { makeBuildingList } from '../../__data__/selectors/buildings'
import { FormConstructor, MultiSelectInput, TextInput } from '../../components/form'

import formStyle from '../../theme/form.css'
import {
    formTitleValidateMin,
    formTitleValidateMax,
    linkValidate,
    buildingsRequired,
} from '../../components/form/validation'

import submit from './submit'

function Component(props) {
    useEffect(() => {
        props.initialize({
            buildings: [],
        })
    }, [])

    return (
        <FormConstructor
            pageTitle='Какую камеру и к чему хотите добавить?'
            goBackLinkText='Отменить и вернуться'
            goBackLinkUrl={URL_LIST.cameras}
            columnCount='5'
            buttonText='Добавить камеру'
            formName={props.formName}
            onSubmit={submit}
            fieldErrors={CAMERA_FORM_FIELDS_ERRORS}
        >
            <Fragment>
                <div className={formStyle.groupSection}>
                    <Field
                        name="name"
                        component={TextInput}
                        type="text"
                        label="Название камеры"
                        value=''
                        placeholder=""
                        validate={[formTitleValidateMin, formTitleValidateMax]}
                    />
                    <Field
                        name="link"
                        component={TextInput}
                        type="text"
                        label="Ссылка на поток"
                        value=''
                        placeholder=""
                        validate={[linkValidate]}
                    />
                </div>
                <div className={formStyle.groupSection}>
                    <Field
                        name="buildings"
                        component={MultiSelectInput}
                        label="Привязана к объектам"
                        options={props.buildingList}
                        validate={[buildingsRequired]}
                    />
                </div>
            </Fragment>
        </FormConstructor>
    )
}
// id, label
const mapStateToProps = (state, ownProps) => createStructuredSelector({
    buildingList: makeBuildingList(),
})

const withConnect = connect(
    mapStateToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.createCamera,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
