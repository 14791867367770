import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import _ from 'lodash'
import classNames from 'classnames'
import { ScrolledToEdge } from 'scrolled-to-edge'

import { URL_LIST } from '../../__data__/constants'
import { getTasks, getNextTasks, patchTask, saveTasksToStoreList, getTasksCount } from '../../__data__/actions/tasks'
import {
    makeTasksSortedList,
    makeIsTasksLoading,
    makeTasksNextLoadingStatus,
    makeTasksCount,
    makeTasksFilterDate,
    makeTasksFilterType,
    makeIsTaskPatchError,
} from '../../__data__/selectors/tasks'
import { makeCheckedBuildings } from '../../__data__/selectors/common'

import { Card, CardContainer, Details, SetPerformerForm, RejectedTaskForm, DateFilter, TypeFilter, SendDataError } from './components'
import { getOrder } from './utils'
import style from './style.css'
import { checkWritePermissionForSection } from "../../__data__/actions/permissions";

function Component(props) {
    const {
        tasksSortedList,
        patchTask,
        getNextTasks,
        checkedBuildings,
        tasksNextLoadingStatus,
        saveTasksToStoreList,
        getTasksCount,
        tasksCount,
        tasksFilterDate,
        tasksFilterType,
        isTaskPatchError,
    } = props

    const isAvailableByPerms = checkWritePermissionForSection('Заявки')
    const buttonText = '+ Создать новую заявку'

    const createdItems = useCallback(() => (_.map(_.get(tasksSortedList, 'created'), i => {
        return {
            id: `${i.id}`,
            content: <Card
                title={i?.service?.name}
                description={i?.information?.description}
                name={`${i?.creator?.first_name} ${i?.creator?.last_name}, ${i?.creator?.position}`}
                date={i.creation_date}
                order={i.order}
            />
        }
    })), [tasksSortedList])()

    const inProgressItems = useCallback(() => (_.map(_.get(tasksSortedList, 'in_progress'), i => {
        return {
            id: `${i.id}`,
            content: <Card
                title={i?.service?.name}
                description={i?.information?.description}
                name={`${i?.creator?.first_name} ${i?.creator?.last_name}, ${i?.creator?.position}`}
                date={i.creation_date}
                targetType={i?.target_type}
                order={i.order}
            />
        }
    })), [tasksSortedList])()

    const doneItems = useCallback(() => (_.map(_.get(tasksSortedList, 'done'), i => {
        return {
            id: `${i.id}`,
            content: <Card
                title={i?.service?.name}
                description={i?.information?.description}
                name={`${i?.creator?.first_name} ${i?.creator?.last_name}, ${i?.creator?.position}`}
                date={i.creation_date}
                order={i.order}
            />
        }
    })), [tasksSortedList])()

    const [performerFormTaskId, setPerformerFormTaskId] = useState(null)
    const [rejectedFormTaskId, setRejectedFormTaskId] = useState(null)

    const [columns, setColumns] = useState()
    const [detailsTaskId, setDetailsTaskId] = useState(null)

    const [isDragging, onDragStart] = useState(false)

    useEffect(() => {
        setColumns(
            {
                created: {
                    name: 'Входящие',
                    items: createdItems
                },
                in_progress: {
                    name: 'В процессе',
                    items: inProgressItems
                },
                done: {
                    name: 'Завершены',
                    items: doneItems
                },
            }
        )
    }, [tasksSortedList])

    const handleScroll = useCallback((e, columnId) => {
        if (e && e.y === 'end') {
            // Получаем вес последнего элемента в колонке
            const items = _.get(tasksSortedList, columnId)
            const lastItemOrder = _.get(_.last(items), 'order')
            getNextTasks(checkedBuildings, columnId, tasksFilterDate, tasksFilterType.value, lastItemOrder)
        }
    }, [tasksSortedList])

    const onDragEnd = useCallback((result) => {
        if (!result.destination) return

        const { draggableId, source, destination } = result

        // Если нет доступа на редактирование Заявок
        if (!checkWritePermissionForSection('Заявки')) return
        // Запрещаем перетаскивать из done в другие колонки
        if (source.droppableId === 'done' && destination.droppableId !== 'done') return

        // Если колонка и позиция не изменилась
        if (source.droppableId === destination.droppableId && source.index === destination.index) return

        // Если драгаем из входящих в 'В процессе', вызываем модалку с выбором исполнителя
        if (source.droppableId === 'created' && destination.droppableId === 'in_progress') {
            setPerformerFormTaskId(result.draggableId)
            // Если драгаем из входящих в завершённые, вызываем модалку с полем ввода причины отмены
        } else if (source.droppableId === 'created' && destination.droppableId === 'done') {
            setRejectedFormTaskId(result.draggableId)
        } else {
            const currentOrder = getOrder(columns, source, destination)

            const data = {stage: destination.droppableId, order: currentOrder}

            // Если сменилась колонка
            if (source.droppableId !== destination.droppableId) {
                if (destination.droppableId === 'created') data['performer'] = null
                if (destination.droppableId === 'done') data['resolution'] = 'resolved'
                // Сохраняем в базу
                patchTask(draggableId ,data)

                // Сохраняем в стейт. (Работает и без этого, но после драга элементы дёргаются)
                const sourceColumn = columns[source.droppableId]
                const destColumn = columns[destination.droppableId]
                const sourceItems = [...sourceColumn.items]
                let destItems = [...destColumn.items]
                const [removed] = sourceItems.splice(source.index, 1)
                destItems.splice(destination.index, 0, removed)
                // Обновляю order двиганного элемента в стейте
                destItems = _.map(destItems, i => {
                    if (i.id === draggableId) {
                        return {...i, content: {...i.content, props: {...i.content.props, order: currentOrder} }}
                    }
                    return i
                })

                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...sourceColumn,
                        items: sourceItems
                    },
                    [destination.droppableId]: {
                        ...destColumn,
                        items: destItems
                    }
                })

                // Сохраняем в стор
                const sourceItemsStore = [...tasksSortedList[source.droppableId]]
                let destItemsStore = [...tasksSortedList[destination.droppableId]]
                const [removedStore] = sourceItemsStore.splice(source.index, 1)
                destItemsStore.splice(destination.index, 0, removedStore)
                destItemsStore = _.map(destItemsStore, i => {
                    if (+i.id === +draggableId) {
                        return { ...i, order: currentOrder }
                    }
                    return i
                })

                saveTasksToStoreList({
                    ...tasksSortedList,
                    [source.droppableId]: sourceItemsStore,
                    [destination.droppableId]: destItemsStore,
                })

                // Актуализируем количество
                getTasksCount(checkedBuildings, 'created', tasksFilterDate, tasksFilterType.value)
                getTasksCount(checkedBuildings, 'in_progress', tasksFilterDate, tasksFilterType.value)
                getTasksCount(checkedBuildings, 'done', tasksFilterDate, tasksFilterType.value)
            } else {
                // Сохраняем в базу
                patchTask(draggableId ,data)

                // Сохраняем в стейт
                const column = columns[source.droppableId]
                let copiedItems = [...column.items]
                const [removed] = copiedItems.splice(source.index, 1)
                copiedItems.splice(destination.index, 0, removed)

                // Обновляю order двиганного элемента в стейте
                copiedItems = _.map(copiedItems, i => {
                    if (i.id === draggableId) {
                        return {...i, content: {...i.content, props: {...i.content.props, order: currentOrder} }}
                    }
                    return i
                })
                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...column,
                        items: copiedItems
                    }
                })

                // Сохраняем в стор
                let copiedItemsStore = [...tasksSortedList[source.droppableId]]
                const [removedStore] = copiedItemsStore.splice(source.index, 1)
                copiedItemsStore.splice(destination.index, 0, removedStore)

                copiedItemsStore = _.map(copiedItemsStore, i => {
                    if (+i.id === +draggableId) {
                        return { ...i, order: currentOrder }
                    }
                    return i
                })

                saveTasksToStoreList({
                    ...tasksSortedList,
                    [source.droppableId]: copiedItemsStore,
                })
            }
        }
    }, [tasksSortedList, columns])

    if (isTaskPatchError) return <SendDataError />

    return (
        <div className={style.container}>
            <div className={style.headerWrapper}>
                <div className={style.header}>
                    <div className={style.filters}>
                        <DateFilter />
                        <TypeFilter />
                    </div>
                    {!isAvailableByPerms ? (
                        <Link to={undefined} className={style.disabledLink}>{buttonText}</Link>
                    ) : (
                        <Link to={URL_LIST.createTask} className={style.addTaskLink}>{buttonText}</Link>
                    )}
                </div>
            </div>
            <div className={style.content}>
                <DragDropContext
                    onDragEnd={result => {
                        onDragEnd(result)
                        onDragStart(false)
                    }}
                    onDragStart={(e) => {onDragStart(e.source.droppableId)}}
                >
                    {_.map(columns, (column, columnId) => {
                        const { name, items } = column
                        return (
                            <div className={style.column} key={columnId}>
                                <h2 className={style.columnTitle}>{`${name} (${_.get(tasksCount, columnId, '-')})`}</h2>
                                <div className={style.columnWrapper}>
                                    <Droppable droppableId={columnId} key={columnId} isDragDisabled={true}>
                                        {(provided, snapshot) => {
                                            const isFromDoneDrag = isDragging === 'done' && provided.droppableProps['data-rbd-droppable-id'] !== 'done' && snapshot.isDraggingOver
                                            const isProgressToDoneDrag = isDragging === 'in_progress' && provided.droppableProps['data-rbd-droppable-id'] === 'done' && snapshot.isDraggingOver
                                            return (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className={classNames(
                                                        style.dragContainer,
                                                        snapshot.isDraggingOver && style.draggingOver,
                                                        isDragging && style.dragging,
                                                        (isFromDoneDrag || !isAvailableByPerms) && style.disabledColumn,
                                                     )}
                                                >
                                                    {/* Окно, запрещающее перетаскивать из столбца done */}
                                                    { (isFromDoneDrag || (snapshot.isDraggingOver && !isAvailableByPerms)) && <div className={style.disabledColumnPopup}></div> }
                                                    {/* Окно-подсказка об успешном завершении заявки */}
                                                    { isProgressToDoneDrag && isAvailableByPerms && <div className={style.successColumnPopup}>
                                                        <div>Подтвердить</div>
                                                        <div>успешное выполнение</div>
                                                    </div> }
                                                    <ScrolledToEdge onChange={(e) => handleScroll(e, columnId)} offset={100}>
                                                        <div className={classNames(
                                                            style.columnContainer,
                                                            snapshot.isDraggingOver && style.columnContainerDragging,
                                                        )}>
                                                            {_.map(items, (item, index) => {
                                                                return (
                                                                    <Draggable
                                                                        key={item.id}
                                                                        draggableId={item.id}
                                                                        index={index}
                                                                        // isDragDisabled={isDragging === 'done' && provided.droppableProps['data-rbd-droppable-id'] !== 'done'}
                                                                    >
                                                                        {(provided, snapshot) => {
                                                                            return (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={{...provided.draggableProps.style}}
                                                                                    onClick={() => setDetailsTaskId(detailsTaskId === item.id ? false : item.id)}
                                                                                >
                                                                                    <CardContainer isDragging={snapshot.isDragging}>
                                                                                        {item.content}
                                                                                    </CardContainer>
                                                                                </div>
                                                                            )
                                                                        }}
                                                                    </Draggable>
                                                                )
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    </ScrolledToEdge>
                                                    {_.get(tasksNextLoadingStatus, columnId) && (
                                                        <div className={style.loaderContainer}>
                                                            <svg className={style.loader} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" height="48" width="48">
                                                                <g transform="translate(16.251,14.1615) scale(0.74,0.74) translate(-30.132,-29.8719)">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.461181,37.4533C3.62035,27.6055,39.7764,1,50,1C60.2232,1,96.3797,27.6055,99.5388,37.4533C102.698,47.3008,88.8874,90.3495,80.6166,96.4357C72.3458,102.521,27.6542,102.521,19.3834,96.4357C11.1123,90.3495,-2.69799,47.3008,0.461181,37.4533ZM75.1101,88.7309C75.2689,88.5464,75.6209,88.1182,76.1462,87.3147C77.0021,86.0056,78.0038,84.1899,79.0957,81.9025C81.2737,77.3398,83.5264,71.5001,85.4772,65.4192C87.4281,59.3382,88.9946,53.273,89.8805,48.2856C90.3246,45.7852,90.5678,43.7206,90.6351,42.1529C90.676,41.1983,90.6406,40.6445,90.6194,40.3996C90.4943,40.1885,90.2011,39.7185,89.6126,38.9688C88.6476,37.7395,87.2518,36.2131,85.4409,34.4541C81.8286,30.9454,77.0402,26.9703,71.932,23.2114C66.8239,19.4526,61.6112,16.0684,57.1996,13.6729C54.9881,12.4719,53.1225,11.5986,51.6686,11.0484C50.7707,10.7086,50.2348,10.5716,50,10.5173C49.7652,10.5716,49.2292,10.7086,48.3312,11.0485C46.8773,11.5987,45.0118,12.4719,42.8002,13.6729C38.3886,16.0684,33.1759,19.4526,28.0678,23.2114C22.9597,26.9703,18.1713,30.9454,14.5591,34.4541C12.7482,36.213,11.3524,37.7395,10.3874,38.9688C9.7989,39.7184,9.50574,40.1885,9.38065,40.3996C9.35939,40.6445,9.32397,41.1983,9.36492,42.1529C9.43218,43.7206,9.67536,45.7852,10.1195,48.2856C11.0053,53.273,12.5719,59.3383,14.5227,65.4192C16.4735,71.5001,18.7261,77.3398,20.9041,81.9025C21.996,84.1899,22.9978,86.0055,23.8537,87.3146C24.379,88.1182,24.7311,88.5464,24.8898,88.7308C25.1123,88.8268,25.6248,89.0344,26.5448,89.2931C28.0406,89.7135,30.0569,90.1177,32.5435,90.4627C37.5035,91.151,43.6854,91.5164,50,91.5164C56.3146,91.5164,62.4965,91.151,67.4565,90.4627C69.9431,90.1177,71.9594,89.7135,73.4552,89.2931C74.3751,89.0345,74.8876,88.8269,75.1101,88.7309Z" fill="#B1BDC8" transform="translate(41.2355,42.1016) scale(0.6,0.6) translate(-50,-52)" />
                                                                    <g transform="translate(41.1654,42.9451) translate(-17.5234,-17.6043)">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M49.8833,74.2655C61.6258,74.2655,71.145,64.7024,71.145,52.9056C71.145,41.1089,61.6258,31.5458,49.8833,31.5458C38.1408,31.5458,28.6217,41.1089,28.6217,52.9056C28.6217,64.7024,38.1408,74.2655,49.8833,74.2655ZM49.8833,82.2461C66.0132,82.2461,79.0889,69.1099,79.0889,52.9056C79.0889,36.7014,66.0132,23.5652,49.8833,23.5652C33.7535,23.5652,20.6777,36.7014,20.6777,52.9056C20.6777,69.1099,33.7535,82.2461,49.8833,82.2461Z" fill="#B1BDC8" transform="translate(17.5234,17.6043) scale(0.6,0.6) translate(-49.8833,-52.9057)"/>
                                                                        <path d="M61.5654,52.9057C61.5654,59.3874,56.3351,64.6419,49.8832,64.6419C43.4312,64.6419,38.2009,59.3874,38.2009,52.9057C38.2009,46.424,43.4312,41.1695,49.8832,41.1695C56.3351,41.1695,61.5654,46.424,61.5654,52.9057Z" fill="#B1BDC8" transform="translate(17.5233,17.6043) scale(0.6,0.6) translate(-49.8832,-52.9057)"/>
                                                                        <path d="M63.902,44.6903C63.902,47.9312,61.2868,50.5584,58.0609,50.5584C54.8349,50.5584,52.2197,47.9312,52.2197,44.6903C52.2197,41.4494,54.8349,38.8222,58.0609,38.8222C61.2868,38.8222,63.902,41.4494,63.902,44.6903Z" fill="white" transform="translate(22.4299,12.6751) scale(0.6,0.6) translate(-58.0609,-44.6903)"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }}
                                    </Droppable>
                                </div>
                            </div>
                        )
                    })}
                </DragDropContext>
                {detailsTaskId && (
                    <Details
                        id={detailsTaskId}
                        changeVisibility={setDetailsTaskId}
                        isDragging={isDragging}
                        handleRejectModal={setRejectedFormTaskId}
                    />
                )}
            </div>
            {performerFormTaskId && (
                <SetPerformerForm
                    taskId={performerFormTaskId}
                    handleModal={setPerformerFormTaskId}
                />
            )}
            {rejectedFormTaskId && (
                <RejectedTaskForm
                    taskId={rejectedFormTaskId}
                    handleModal={setRejectedFormTaskId}
                    changeDetailsVisibility={setDetailsTaskId}
                />
            )}
        </div>
    )
}

function Wrapper(props) {
    const {
        getTasks,
        getNextTasks,
        checkedBuildings,
        tasksSortedList,
        patchTask,
        // isTasksLoading,
        tasksNextLoadingStatus,
        saveTasksToStoreList,
        getTasksCount,
        tasksCount,
        tasksFilterDate,
        tasksFilterType,
        isTaskPatchError,
    } = props

    useEffect(() => {
        if (!_.isEmpty(checkedBuildings) && tasksFilterType?.value) {
            getTasks(checkedBuildings, 'created', tasksFilterDate, tasksFilterType.value)
            getTasks(checkedBuildings, 'in_progress', tasksFilterDate, tasksFilterType.value)
            getTasks(checkedBuildings, 'done', tasksFilterDate, tasksFilterType.value)
            getTasksCount(checkedBuildings, 'created', tasksFilterDate, tasksFilterType.value)
            getTasksCount(checkedBuildings, 'in_progress', tasksFilterDate, tasksFilterType.value)
            getTasksCount(checkedBuildings, 'done', tasksFilterDate, tasksFilterType.value)
        }
    }, [checkedBuildings, tasksFilterDate, tasksFilterType])

    // Проверяем что на все запросы тасок пришли ответы
    // if (!isTasksLoading) { // Вроде как не нужна. Хз зачем я делал. Мб потом всплывёт.
        return <Component
            tasksSortedList={tasksSortedList}
            patchTask={patchTask}
            getNextTasks={getNextTasks}
            checkedBuildings={checkedBuildings}
            tasksNextLoadingStatus={tasksNextLoadingStatus}
            saveTasksToStoreList={saveTasksToStoreList}
            getTasksCount={getTasksCount}
            tasksCount={tasksCount}
            tasksFilterDate={tasksFilterDate}
            tasksFilterType={tasksFilterType}
            isTaskPatchError={isTaskPatchError}
        />
    // }
    // return null
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    tasksFilterDate: makeTasksFilterDate(),
    tasksFilterType: makeTasksFilterType(),
    tasksSortedList: makeTasksSortedList(),
    isTasksLoading: makeIsTasksLoading(),
    tasksNextLoadingStatus: makeTasksNextLoadingStatus(),
    tasksCount: makeTasksCount(),
    isTaskPatchError: makeIsTaskPatchError(),
})

const mapDispatchToProps = {
    getTasks,
    getNextTasks,
    patchTask,
    saveTasksToStoreList,
    getTasksCount,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Wrapper)
