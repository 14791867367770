import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { reduxForm, getFormValues, Field } from 'redux-form'
import _ from 'lodash'

import {
    NEWS_BUILDING_MENU_ITEMS_ID,
    FORM_NAMES, URL_LIST, COUNT_TOGGLE_VALUES, NEWS_TYPES_LIST, NEWS_FORM_FIELDS_ERRORS
} from '../../__data__/constants'
import { FormConstructor, RadioGroupMenu, TextArea, TextInput } from '../../components/form'
import HomeSelectInput from '../../components/news-form-fields/home-select-input'
import formStyle from '../../theme/form.css'
import {
    filesValidation,
    formDescriptionValidateMax,
    formDescriptionValidateMin,
    formTitleValidateMax,
    formTitleValidateMin,
    homeNumberRequired
} from '../../components/form/validation'
import FormFactory from '../../components/news-form-fields/form-factory'
import UploadComponent from '../../components/s3/uploader'

import submit from './submit'

function Component(props) {
    useEffect(() => {
        props.initialize({
            newsType: NEWS_TYPES_LIST[0]['value'],
            buildingPropsToggle: NEWS_BUILDING_MENU_ITEMS_ID.home,
            porchPropsToggle: NEWS_BUILDING_MENU_ITEMS_ID.porch,
            floorsCount: COUNT_TOGGLE_VALUES.all,
            porchesCount: COUNT_TOGGLE_VALUES.all,
            flats: [],
        })
    }, [])

    const buildingSelectValue = _.get(props, 'formValues.homeNumber')

    return (
        <FormConstructor
            pageTitle='Кому и о чём хотите рассказать?'
            goBackLinkText='Вернуться к объявлениям'
            goBackLinkUrl={URL_LIST.news}
            columnCount='5'
            buttonText='Опубликовать объявление'
            formName={props.formName}
            onSubmit={submit}
            fieldErrors={NEWS_FORM_FIELDS_ERRORS}
        >
            <Fragment>
                <div className={formStyle.groupSection}>
                    <Field
                        name='homeNumber'
                        component={HomeSelectInput}
                        label='Объект'
                        validate={[homeNumberRequired]}
                        defaultOpen={true}
                        formName={props.formName}
                    />
                    { buildingSelectValue && <FormFactory buildingValue={buildingSelectValue} formValues={props.formValues} formName={props.formName} /> }
                </div>
                {
                    buildingSelectValue && (
                        <Fragment>
                            <div className={formStyle.groupSection}>
                                <Field
                                    name="newsTitle"
                                    component={TextInput}
                                    type="text"
                                    label="Заголовок объявления"
                                    value=''
                                    placeholder=""
                                    validate={[formTitleValidateMin, formTitleValidateMax]}
                                />
                                <Field
                                    name="newsDescription"
                                    component={TextArea}
                                    type="text"
                                    label="Содержание"
                                    value=''
                                    placeholder=""
                                    validate={[formDescriptionValidateMin, formDescriptionValidateMax]}
                                />
                                <RadioGroupMenu name='newsType' items={NEWS_TYPES_LIST} />
                            </div>
                            <div className={formStyle.groupSection}>
                                <Field
                                    name="files"
                                    component={UploadComponent}
                                    value=''
                                    formName={props.formName}
                                    validate={[filesValidation]}
                                />
                            </div>
                        </Fragment>
                    )
                }
            </Fragment>
        </FormConstructor>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
})

const withConnect = connect(
    mapStateToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.createNews,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
