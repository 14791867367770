import _ from 'lodash'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const LoginValidationMessages = {
    errorInvalidCredentials: {
        name: 'Неправильно введён телефон или пароль',
        password: 'Неправильно введён телефон или пароль'
    }
}

export const RecoverValidationMessages = {
    errorInvalidCredentials: {
        name: 'Неправильно введён телефон или пароль',
    }
}

export const passwordConfirmValidationMessages = {
    errorInvalidCredentials: {
        name: 'Не верный код',
    }
}

export const passwordRepeatValidationMessages = {
    errorInvalidCredentials: {
        name: 'введите пароль и повторите его',
    }
}

export const formTitleValidateMin = value => {
    if (!value || value.length < 8) return 'слишком мало'
    return undefined
}

export const formTitleValidateMax = value => {
    if (value.length > 100) return 'слишком много'
    return undefined
}

export const formDescriptionValidateMin = value => {
    if (!value || value.length < 20) return 'слишком мало'
    return undefined
}

export const formDescriptionValidateMax = value => {
    if (value.length > 400) return 'слишком много'
    return undefined
}

export const required = value => !_.isEmpty(value) ? undefined : 'обязательное поле'
export const flatsNumbersRequired = (value) => value && _.size(value) > 2 ? undefined : 'укажите минимум 3 квартиры'
export const homeNumberRequired = value => !_.isEmpty(value) ? undefined : 'выберите объект'
export const flatNumberRequired = value => !_.isEmpty(value) ? undefined : 'выберите квартиру'
export const taskRequired = value => !_.isEmpty(value) ? undefined : 'выберите услугу'

export const buildingSettingsDayRequired = value => !_.isEmpty(value) ? undefined : 'укажите дату'

export const nameValidate = value => {
    if (!value || value.length < 1 || value.length > 60) return 'должно содержать от 1 до 60 символов'
    return undefined
}

export const surnameValidate = value => {
    if (!value || value.length < 1 || value.length > 60) return 'должна содержать от 1 до 60 символов'
    return undefined
}

export const positionValidate = value => {
    if (!value || value.length < 3 || value.length > 60) return 'должна содержать от 1 до 60 символов'
    return undefined
}

const passwordRegexp = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/
export const passwordCreateValidate = value => {
    if (!value || !value.match(passwordRegexp)) return 'латиница, 8+ символов, минимум одна заглавная буква и одна цифра'
    return undefined
}

export const phoneValidate = value => {
    if (!isValidPhoneNumber(value)) return 'введите корректный номер'
    return undefined
}

export const maskedPhoneValidate = value => {
    const v = value?.replaceAll('+7', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')
    if (!v || v.length !== 10) return 'введите корректный номер'
    return undefined
}

const linkRegexp = 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)'
export const linkValidate = value => {
    if (!value || !value.match(linkRegexp)) return 'введите корректную ссылку'
    return undefined
}
export const buildingsRequired = (value) => value && _.size(value) > 0 ? undefined : 'укажите объекты'

export const filesValidation = value => {
    if (_.find(value, ['status', 'error'])) return 'не удалось загрузить файл'
    if (!_.every(value, ['status', 'done'])) return 'обрабатываем файлы'
    return undefined
}

export const sexValidation = value => !_.isEmpty(value) ? undefined : 'выберите пол'

export const organisationValidation = value => !_.isEmpty(value) ? undefined : 'выберите организацию'

export const servicesRequired = (value) => value && _.size(value) > 0 ? undefined : 'выберите сервисы для работы по ним в разделе "Заявки"'
