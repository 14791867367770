import React from 'react'
import { reduxForm, Field } from 'redux-form'

import { FORM_NAMES, URL_LIST } from '../../__data__/constants'
import { FormConstructor } from '../../components/form'

import Avatar from './avatar'
import submit from './submit'

function Component(props) {

    return (
        <FormConstructor
            pageTitle='Добавьте аватар'
            goBackLinkText='Вернуться к профилю'
            goBackLinkUrl={URL_LIST.profile}
            columnCount='7'
            buttonText='Сохранить'
            formName={props.formName}
            onSubmit={submit}
        >
            <Field
                name="preview"
                component={Avatar}
                formName={props.formName}
            />
        </FormConstructor>
    )
}

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.avatarForm,
    onSubmit: submit
}) (Component)

export default Component
