import axios from 'axios'

import * as types from '../actions-types'
import { getToken } from '../utils'
import {MODERATOR_STORAGE_NAME, PROFILE_COMPANY_ID, PROFILE_USER_ID} from '../constants'

export const fetchGetProfile = () => ({ type: types.GET_PROFILE })
export const fetchGetProfileSuccess = (data) => ({ type: types.GET_PROFILE_SUCCESS, data })
export const fetchGetProfileFail = (error) => ({ type: types.GET_PROFILE_FAIL, error })

export const fetchGetUserProfile = () => ({ type: types.GET_USER_PROFILE })
export const fetchGetUserProfileSuccess = (data) => ({type: types.GET_USER_PROFILE_SUCCESS, data})
export const fetchGetUserProfileFail = () => ({type: types.GET_USER_PROFILE_FAIL})

export const fetchSetAvatar = () => ({ type: types.SET_AVATAR })
export const fetchSetAvatarSuccess = (data) => ({ type: types.SET_AVATAR_SUCCESS, data })
export const fetchSetAvatarFail = (error) => ({ type: types.SET_AVATAR_FAIL, error })

export const fetchDeleteAvatar = () => ({ type: types.DELETE_AVATAR })
export const fetchDeleteAvatarSuccess = (data) => ({ type: types.DELETE_AVATAR_SUCCESS, data })
export const fetchDeleteAvatarFail = (error) => ({ type: types.DELETE_AVATAR_FAIL, error })

export const fetchLoggedSms = () => ({ type: types.LOGGED_SMS })
export const fetchLoggedSmsSuccess = (data) => ({ type: types.LOGGED_SMS_SUCCESS, data })
export const fetchLoggedSmsFail = (error) => ({ type: types.LOGGED_SMS_FAIL, error })

export const fetchLoggedConfirmationCode = () => ({ type: types.LOGGED_CONFIRMATION_CODE })
export const fetchLoggedConfirmationCodeSuccess = (data) => ({ type: types.LOGGED_CONFIRMATION_CODE_SUCCESS, data })
export const fetchLoggedConfirmationCodeFail = (error) => ({ type: types.LOGGED_CONFIRMATION_CODE_FAIL, error })

export const getProfile = (token= getToken(MODERATOR_STORAGE_NAME)) => dispatch => {
    dispatch(fetchGetProfile())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        },
        url: '/api/crm/profile/current/',
    })
        .then(response => {
            dispatch(fetchGetProfileSuccess(response.data))
            localStorage.setItem(PROFILE_COMPANY_ID, response.data.company.id)
            localStorage.setItem(PROFILE_USER_ID, response.data.user)
        })
        .catch(error => {
            dispatch(fetchGetProfileFail(error))
        })
}

export const getUserProfile = (id) => dispatch => {
    dispatch(fetchGetUserProfile())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/authorized/profile/?chat_id=${id}`
    }).then(response => {
        dispatch(fetchGetUserProfileSuccess(response.data))
    }).catch(error => {
        dispatch(fetchGetUserProfileFail(error))
    })
}

export const setAvatar = (avatar) => dispatch => {
    dispatch(fetchSetAvatar())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/avatar/`,
        data: {avatar},
    })
        .then(response => {
            dispatch(fetchSetAvatarSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchSetAvatarFail(error))
        })
}

export const deleteAvatar = (avatar) => dispatch => {
    dispatch(fetchDeleteAvatar())

    return axios({
        method: 'DELETE',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/avatar/delete_current/`,
        data: {avatar},
    })
        .then(response => {
            dispatch(fetchDeleteAvatarSuccess(response.data))
            dispatch(getProfile())
        })
        .catch(error => {
            dispatch(fetchDeleteAvatarFail(error))
        })
}

export const sendLoggedSms = (id) => dispatch => {
    dispatch(fetchLoggedSms())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/authorized/forgot_pass/send_confirm_code/`
    }).then(response => {
        dispatch(fetchLoggedSmsSuccess(response.data))
    }).catch(error => {
        dispatch(fetchLoggedSmsFail(error))
    })
}

export const setLoggedConfirmationCode = data => dispatch => {
    dispatch(fetchLoggedConfirmationCode())
    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        data,
        url: `/api/authorized/forgot_pass/confirm_reset_password/`,
    })
        .then(response => {
            dispatch(fetchLoggedConfirmationCodeSuccess(response))
        })
        .catch(error => {
            dispatch(fetchLoggedConfirmationCodeFail(error))
        })
}
