import classNames from "classnames";
import React, { useCallback, useEffect } from "react"

import {createStructuredSelector} from "reselect"
import {connect} from "react-redux"
import {compose} from "redux"
import _ from 'lodash'
import FocusLock from 'react-focus-lock'

import { getStoryFull, setStory } from "../../__data__/actions/news"

import Stories from 'react-insta-stories';
import { makeCurrentStoryIndex, makeStories, makeStoriesFull } from "../../__data__/selectors/story";
import style from './style.css'

function Component(props) {
    const {stories, currentStoryIndex, storyListFull} = props
    const [paused, setPaused] = React.useState(false)
    const [currentStoryIndexLocal, setCurrentStoryIndexLocal] = React.useState(0)
    const [currentFrameIndexLocal, setFrameStoryIndexLocal] = React.useState(0)

    useEffect(() => {
        if ((currentStoryIndex === 0 || currentStoryIndex) && currentStoryIndex !== -1) {
            setCurrentStoryIndexLocal(currentStoryIndex)
            _.forEach(stories, i => {
                props.getStoryFull(i.id)
            })
        }
    }, [stories, currentStoryIndex])

    const escFunction = useCallback(event => {
        if (event.keyCode === 27) {
            setCurrentStoryIndexLocal(0)
            setFrameStoryIndexLocal(0)
            props.setStory([])
        }
    }, [])


    useEffect(() => {
        document.addEventListener("keydown", escFunction, false)

        if (currentStoryIndex !== -1) {
            document.body.style.overflow = "hidden"
            document.body.style.height = "100vh"
        } else {
            document.body.style.overflow = "unset"
            document.body.style.height = "auto"
        }

        return () => {
            document.removeEventListener("keydown", escFunction, false)
        }
    }, [currentStoryIndex])


    const handleCloseClick = useCallback(event => {
        setCurrentStoryIndexLocal(0)
        setFrameStoryIndexLocal(0)
        props.setStory([])
    }, [])


    if (currentStoryIndex !== 0 && (!currentStoryIndex || currentStoryIndex === -1)) {
        return null
    }

    const header = (title) => {
        return (<div className={style.headerStory}>{title}</div>)
    }

    const onStoryStart = (currIndex) => {
        setPaused(true)
        setFrameStoryIndexLocal(currIndex)
        setPaused(false)
    }

    const onAllStoriesEnd = () => {
        setPaused(true)
        setFrameStoryIndexLocal(0)
        setPaused(false)
        handleRightClick()
    }

    const handleRightClick = () => {
        setPaused(true)
        const newIndex = currentStoryIndexLocal + 1
        if (newIndex >= stories.length)
            setCurrentStoryIndexLocal(0)
        else {
            setCurrentStoryIndexLocal(newIndex)
        }
        setFrameStoryIndexLocal(0)
        setPaused(false)
    }

    const handleLeftClick = () => {
        setPaused(true)
        const newIndex = currentStoryIndexLocal - 1
        if (newIndex < 0) {
            setCurrentStoryIndexLocal(stories.length - 1)
        } else {
            setCurrentStoryIndexLocal(newIndex)
        }
        setFrameStoryIndexLocal(0)
        setPaused(false)
    }

    const handleFrameRightClick = () => {
        if (currentFrameIndexLocal + 1 >= storyListFull[currentStoryIndexLocal].stories.length) {
            handleRightClick()
        } else {
            setFrameStoryIndexLocal(currentFrameIndexLocal + 1)
        }
    }

    const handleFrameLeftClick = () => {
        if (currentFrameIndexLocal - 1 < 0) {
            handleLeftClick()
        } else {
            setFrameStoryIndexLocal(currentFrameIndexLocal - 1)
        }
    }

    const prevIndex = currentStoryIndexLocal === 0 ? storyListFull.length - 1 : currentStoryIndexLocal - 1
    const nextIndex = currentStoryIndexLocal === storyListFull.length - 1 ? 0 : currentStoryIndexLocal + 1

    return (
        <div className={style.container}>
            <div className={style.bg}/>
            <div className={style.wrapper}>
                <div className={style.header}>
                    <button className={style.closeButton} onClick={handleCloseClick}/>
                </div>
                <FocusLock className={classNames(style.storyBlock)}>
                    <div>
                        <div className={classNames(style.storyArrow)} onClick={handleLeftClick}>
                            <img className={classNames(style.storyArrowCover)} src={storyListFull[prevIndex]?.cover} alt="story-cover"/>
                        </div>
                    </div>
                    <button className={classNames(style.arrow, style.clickLeft)} onClick={handleFrameLeftClick}/>
                    {storyListFull.length && storyListFull[currentStoryIndexLocal]?.stories &&
                        <div key={storyListFull[currentStoryIndexLocal].id}
                             className={classNames(style.storyBlockWrapper)}><Stories
                            stories={storyListFull[currentStoryIndexLocal]?.stories}
                            defaultInterval={1500}
                            width={460}
                            height={'90vh'}
                            header={header}
                            onStoryStart={onStoryStart}
                            onAllStoriesEnd={onAllStoriesEnd}
                            isPaused={paused}
                            key={storyListFull[currentStoryIndexLocal].id}
                            currentIndex={currentFrameIndexLocal}
                        />

                        </div>}
                    <button onClick={handleFrameRightClick} className={classNames(style.arrow, style.clickRight)}/>
                    <div>
                        <div onClick={handleRightClick} className={classNames(style.storyArrow)}>
                            <img className={classNames(style.storyArrowCover)} src={storyListFull[nextIndex]?.cover} alt="story-cover"/>
                        </div>
                    </div>
                </FocusLock>
            </div>
        </div>
    )
}


const mapStateToProps = () => createStructuredSelector({
    stories: makeStories(),
    storyListFull: makeStoriesFull(),
    currentStoryIndex: makeCurrentStoryIndex(),
})

const mapDispatchToProps = {
    getStoryFull,
    setStory,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
