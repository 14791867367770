import React, { useEffect, useCallback } from 'react'
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { getStoryList, setStory } from "../../../../__data__/actions/news";
import { makeCheckedBuildings } from "../../../../__data__/selectors/common";
import { makeStories } from "../../../../__data__/selectors/story";
import { default as Item } from './components/story-item'
import style from './style.css'
import _ from 'lodash'

function Story(props) {
    const {stories, checkedBuildings, getStoryList} = props

    useEffect(() => {
        checkedBuildings && getStoryList(checkedBuildings)
    }, [checkedBuildings])

    const handleStoryClick = useCallback((e, story) => {
        e.preventDefault()
        props.setStory(story)
    }, [])

    return (
        <div className={style.container}>
            {_.map(stories, (story) => (
                <Item key={story.id} handleStoryClick={handleStoryClick}  story={story} />
            ))}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    stories: makeStories(),
    checkedBuildings: makeCheckedBuildings(),
})

const mapDispatchToProps = {
    setStory,
    getStoryList,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Story)
