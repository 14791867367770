import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {Field, reduxForm, isValid, getFormSyncErrors, FieldArray} from 'redux-form'
import _ from 'lodash'
import queryString from 'query-string'

import {TextInput, PhoneInput, FormConstructor, RadioGroupMenu} from '../../../../components/form'
import { getResident } from '../../../../__data__/actions/residents'
import { makeResidentFormInit } from '../../../../__data__/selectors/residents'
import {
    nameValidate,
    surnameValidate,
    phoneValidate, required,
} from '../../../../components/form/validation'
import {FORM_NAMES, URL_LIST, RESIDENT_FORM_FIELDS_ERRORS, SEX_ITEMS} from '../../../../__data__/constants'
import formStyle from '../../../../theme/form.css'
import {submitEditedResident} from '../../utils/submit'
import {renderUnits} from "../../utils/renderUnits";

function Component({formName, getResident}) {
    const queryParams = queryString.parse(window.location.search)
    const residentId = _.get(queryParams, 'id')

    useEffect(() => {
        getResident(residentId)
    }, [residentId])

    return (
        <FormConstructor
            pageTitle='Изменить данные резидента'
            goBackLinkText='Вернуться к резидентам'
            goBackLinkUrl={URL_LIST.residents}
            columnCount='5'
            buttonText='Сохранить изменения'
            formName={formName}
            fieldErrors={RESIDENT_FORM_FIELDS_ERRORS}
        >
            <div className={formStyle.groupSection}>
                <Field
                    name="name"
                    component={TextInput}
                    type="text"
                    label="Имя"
                    validate={[nameValidate]}
                />
                <Field
                    name="surname"
                    component={TextInput}
                    type="text"
                    label="Фамилия"
                    validate={[surnameValidate]}
                />
                <RadioGroupMenu
                    name="gender"
                    items={SEX_ITEMS}
                    validate={[required]}
                />
                <Field
                    size="auto"
                    name="phoneNumber"
                    label="Телефон"
                    description="На этот номер будет отправлено приглашение"
                    component={PhoneInput}
                    type="text"
                    validate={[phoneValidate]}
                />
            </div>
            <div className={formStyle.groupSection}>
                <FieldArray name="units" component={renderUnits} formName={formName} />
            </div>
        </FormConstructor>
    )
}

const mapStateToProps = createStructuredSelector({
    initialValues: makeResidentFormInit(),
    isFormValid: isValid(FORM_NAMES.editResident),
    fieldErrors: getFormSyncErrors(FORM_NAMES.editResident),
})

const mapDispatchToProps = {
    getResident,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.editResident,
    onSubmit: submitEditedResident,
    enableReinitialize: true
}) (Component)

export const EditResidentForm =compose(withConnect)(Component)
